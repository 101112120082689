import { toast } from "react-toastify";
import {
  getEventsFailure,
  getEventsRequest,
  getEventsSuccess,
  getEventStatusFailure,
  getEventStatusRequest,
  getEventStatusSuccess,
  getOngoingEventFailure,
  getOngoingEventRequest,
  getOngoingEventSuccess,
  getShowEventsFailure,
  getShowEventsRequest,
  getShowEventsSuccess,
} from "../store/actions/EventActions";

import axiosConfig from "../utils/axiosConfig";

export const GetEvents = (data) => {
  return (dispatch) => {
    dispatch(getEventsRequest());

    return axiosConfig
      .post("/client/getEvents", data)
      .then((res) => {
        if (res.data?.status === 1) {
          dispatch(getEventsSuccess(res.data?.responsedata?.details));
        } else {
          // toast.error(res?.data?.message);
          dispatch(getEventsFailure(res.data?.message));
        }
      })
      .catch((err) => {
        toast.error("Error Fetching Events....");
        dispatch(getEventsFailure(err));
      });
  };
};

// Get Event Status
export const GetEventStatus = () => {
  return (dispatch) => {
    dispatch(getEventStatusRequest());

    axiosConfig
      .post("/client/getAllEventStatus", "")
      .then((res) => {
        if (res.data?.status === 1) {
          dispatch(getEventStatusSuccess(res.data?.responsedata?.details));
        } else {
          toast.error(res?.data?.message);
          dispatch(getEventStatusFailure(res.data?.message));
        }
      })
      .catch((err) => {
        toast.error("Error Fetching Event Status....");
        dispatch(getEventStatusFailure(err));
      });
  };
};

// Get onGoing Event
export const GetOngoingEvent = (data) => {
  return (dispatch) => {
    dispatch(getOngoingEventRequest());

    axiosConfig
      .post("/client/ongoingEvent", data)
      .then((res) => {
        if (res.data?.status === 1) {          
          dispatch(getOngoingEventSuccess(res.data?.responsedata));
        } else {
          toast.error(res?.data?.message);
          dispatch(getOngoingEventFailure(res.data?.message));
        }
      })
      .catch((err) => {        
        toast.error("Error Fetching Ongoing Event...");
        dispatch(getOngoingEventFailure(err));
      });
  };
};

// show event
export const ShowEvents = (data) => {
  return (dispatch) => {
    dispatch(getShowEventsRequest());

    return axiosConfig
      .post("/client/showEvent", data)
      .then((res) => {
        if (res.data?.status === 1) {
          dispatch(getShowEventsSuccess(res.data?.responsedata?.details || []));
        } else {
          // toast.error(res?.data?.message);
          dispatch(getShowEventsFailure(res.data?.message));
        }
      })
      .catch((err) => {
        toast.error("Error Fetching Show Events....");
        dispatch(getShowEventsFailure(err));
      });
  };
};