import { Link } from "react-router-dom";

export function SendOTP({
  setEmailInput,
  data,
  handleSendOtp,
  // isValidEmail,
  emailInput,
}) {
  return (
    <>
      <h1 className="text-black">Forgot Password</h1>
      {/* <p className="fw-medium text-muted">
        Please enter your <b>Username</b>.
      </p> */}
      <form
        className="forgot"
        onSubmit={(e) => {
          e.preventDefault();
          handleSendOtp();
        }}
      >
        <div className="mb-4">
          <input
            type="text"
            className={
              "form-control form-control-lg py-4" +
              (data.otpSendRequested ? " is-invalid" : "")
            }
            value={emailInput}
            name="username"
            placeholder="username"
            onChange={(e) => {
              // setIsValidEmail(() => validateEmail(e.target.value));
              setEmailInput(e.target.value);
            }}
          />
          {/* {data.otpSendRequested && (
              <span className="help-block invalid-feedback fadeIn">
                {!isValidEmail && "Please Enter a Valid Email"}
              </span>
            )} */}
        </div>
        <div className="forgorbtndiv">
          <button
            type="button"
            onClick={handleSendOtp}
            className="btn btn-alt-primary float-right"
            disabled={data.otpSendLoading}
          >
            {!data.otpSendLoading ? (
              <>
                <i className="fa fa-fw fa-envelope opacity-50">&nbsp;</i>
                Send OTP
              </>
            ) : (
              <>
                <i className="fas fa-spinner fa-spin" />
                &nbsp;Please Wait...
              </>
            )}
          </button>
          <div className="block-options">
            <Link
              className="btn-block-option js-bs-tooltip-enabled"
              to="/"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Sign In"
              data-bs-original-title="Sign In"
            >
              {/* <i style={{ color: "black" }} className="fa fa-sign-in-alt"></i> */}
              Back to Login
            </Link>
          </div>
        </div>
      </form>
    </>
  );
}

export default SendOTP;
