import { directoryActionTypes } from "../actionTypes/directoryActionTypes";

const initialState = {
    loading: false,
    data: [],
    error: "",
  };
  
  export const directoryReducer = (state = initialState, action) => {
    switch (action.type) {
      // List
      case directoryActionTypes.DIRECTORY_LIST_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
        };
      case directoryActionTypes.DIRECTORY_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: "",
        };
  
      case directoryActionTypes.DIRECTORY_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          data: "",
          error: action.payload,
        };

        default:
      return state;
  }
};
