import { combineReducers } from "redux";
import {
  authReducer,
  BlogReducer,
  contactReducer,
  DashboardReducer,
  directoryReducer,
  EventReducer,
  notificationReducer,
  tempDocumentReducer,
  uiReducer,  
} from "./reducer";

const appReducer = combineReducers({
  ui: uiReducer,
  user: authReducer,
  dashboard: DashboardReducer,
  event: EventReducer,
  tempDocument: tempDocumentReducer,
  contact: contactReducer,
  notification: notificationReducer,
  blog: BlogReducer,  
  directory: directoryReducer,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    state = initialState;
  }

  return appReducer(state, action);
};

export default rootReducer;
