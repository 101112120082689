import { DashboardActionTypes } from "../actionTypes/DashboardActionTypes";

export const getDashboardRequest = () => ({
  type: DashboardActionTypes.GET_DASHBOARD_REQUEST,
});

export const getDashboardSuccess = (payload) => ({
  type: DashboardActionTypes.GET_DASHBOARD_SUCCESS,
  payload,
});

export const getDashboardFailure = (payload) => ({
  type: DashboardActionTypes.GET_DASHBOARD_FAILURE,
  payload,
});
