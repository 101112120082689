export const contactActionTypes = {
  // List
  CONTACT_LIST_REQUEST: "CONTACT_LIST_REQUEST",
  CONTACT_LIST_SUCCESS: "CONTACT_LIST_SUCCESS",
  CONTACT_LIST_FAILURE: "CONTACT_LIST_FAILURE",

  // Add Contact
  ADD_CONTACT_REQUEST: "ADD_CONTACT_REQUEST",
  ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
  ADD_CONTACT_FAILURE: "ADD_CONTACT_FAILURE",
};
