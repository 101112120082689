import { toast } from "react-toastify";
import {
  contactListFailure,
  contactListRequest,
  contactListSuccess,
  addContactRequest,
  addContactFailure,
  addContactSuccess,
} from "../store/actions/contactActions";
import axiosConfig from "../utils/axiosConfig";

// list
export const ContactListing = () => { 
  return (dispatch) => {
    dispatch(contactListRequest());

    axiosConfig
      .post("/client/listContacts", "")
      .then((res) => {
        if (res.data?.status === 1) {
          dispatch(contactListSuccess(res.data?.responsedata?.details));
        } else {
          toast.error(res?.data?.message);
          dispatch(contactListFailure(res.data?.message));
        }
      })
      .catch((err) => {
        toast.error("Error Fetching contact Details....");
        dispatch(contactListFailure(err));
      });
  };
};

// Add Contact
export const AddContact = (data) => {
  return (dispatch) => {
    dispatch(addContactRequest());

    return axiosConfig
      .post("/client/addContacts", data)
      .then((res) => {
        if (res.data?.status === 1) {
          dispatch(addContactSuccess(res.data?.responsedata?.details));
          return true;
        } else {
          toast.error(res?.data?.message);
          dispatch(addContactFailure(res.data?.message));
          return false;
        }
      })
      .catch((err) => {
        toast.error("Error adding Contact....");
        dispatch(addContactFailure(err));
        return false;
      });
  };
};
