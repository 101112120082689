import { Skeleton } from "@mui/material";
import { memo, useMemo } from "react";
import { mainLightColor } from "../../utils/theme";

const animations = ["pulse", "wave", false];
const RandomSkeleton = ({
  height,
  color,
  width
}) => {
  const randomAnimation = useMemo(() => {
    return (
      <Skeleton
        animation={animations[Math.floor(Math.random() * animations.length)]}
        variant="rectangular"
        // width={120}

        // height="100%"
        sx={{ bgcolor: color || mainLightColor, borderRadius: "10px", width: width || "100%", height }}
      />
    );
  }, [color, height, width]);
  return randomAnimation;
};

export default memo(RandomSkeleton);
