import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UploadFile } from "../../services/TempDocumentService";

export default function DocumentUpload() {
  // Dialog State
  const initialFormData = {
    file_name: "",
    priority: "medium",
  };
  
  const [formState, setFormState] = useState(initialFormData);
  const [selectedFiles, setSelectedFiles] = useState();
  const [theInputKey, setTheInputKey] = useState();

  const dispatch = useDispatch();

  const { error, uploading } = useSelector((state) => ({
    uploading: state?.tempDocument?.fileUpload?.uploading,
    error: state?.tempDocument?.fileUpload?.error,
  }));


  // Upload File Btn with API call
  const handleUploadFileBtn = async (e) => {
    e.preventDefault();
    if (!selectedFiles || selectedFiles?.length <= 0) {
      return alert("Please Select A File.");
    }
    const formData = new FormData();
    formData.append("file_name", formState?.file_name);
    formData.append("priority", formState?.priority);
    formData.append("file", selectedFiles[0]);
    await dispatch(UploadFile(formData));
    if (!uploading && !error) {
      setSelectedFiles();
      setFormState({ priority: "medium" });
      setTheInputKey(Date.now.toString());
    }
  };

  // Change Priority
  const handlePriorityChange = (e) => {
    setFormState((prev) => ({
      ...prev,
      priority: e.target.value,
    }));
  };

  return (
    <form onSubmit={handleUploadFileBtn} className="Document_Upload">
      <div>
        {/* File Name */}
        <label>
          File Name<span className="text-danger">*</span>
        </label>
        <>
          <input
            type="text"
            value={formState?.file_name || ""}
            placeholder="File Name"
            className={"form-control form-control-md mt-2 mb-3"}
            onChange={(e) =>
              setFormState((prev) => ({ ...prev, file_name: e.target.value }))
            }
          />
        </>
        {/* Priority Radio Btns */}
        <FormControl component="fieldset">
          <label component="legend">Priority</label>
          <RadioGroup
            onChange={handlePriorityChange}
            row
            aria-label="priority"
            name="priority"
            value={formState?.priority}
          >
            <FormControlLabel
              sx={{ color: "green" }}
              value="low"
              control={<Radio />}
              label="Low"
            />
            <FormControlLabel
              value="medium"
              control={<Radio />}
              label="Medium"
              sx={{ color: "#b3b300" }}
            />
            <FormControlLabel
              value="high"
              control={<Radio />}
              label="High"
              sx={{ color: "red" }}
            />
          </RadioGroup>
        </FormControl>
        {/* Upload File */}
        <div className="uploadfile">
          <label>
            Upload Your File<span className="text-danger">*</span>{" "}
          </label>
          <input
            type="file"
            key={theInputKey || ""}
            onChange={(e) => {
              setSelectedFiles(e.target.files);
            }}
            className={"form-control form-control-md mt-2"}
          />
        </div>
      </div>
      <button type="submit">Upload</button>
    </form>
  );
}
