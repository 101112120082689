import { tempDocumentActionTypes } from "../actionTypes/tempDocumentActionTypes";

// List
export const tempDocumentListRequest = () => {
  return {
    type: tempDocumentActionTypes.TEMP_DOCUMENT_LIST_REQUEST,
  };
};

export const tempDocumentListSuccess = (payload) => {
  return {
    type: tempDocumentActionTypes.TEMP_DOCUMENT_LIST_SUCCESS,
    payload,
  };
};

export const tempDocumentListFailure = (payload) => {
  return {
    type: tempDocumentActionTypes.TEMP_DOCUMENT_LIST_FAILURE,
    payload,
  };
};

//  Upload File
export const uploadFileRequest = () => {
  return {
    type: tempDocumentActionTypes.UPLOAD_FILE_REQUEST,
  };
};

export const uploadFileSuccess = (payload) => {
  return {
    type: tempDocumentActionTypes.UPLOAD_FILE_SUCCESS,
    payload,
  };
};

export const uploadFileFailure = (payload) => {
  return {
    type: tempDocumentActionTypes.UPLOAD_FILE_FAILURE,
    payload,
  };
};

//  Delete File
export const deleteFileRequest = () => {
  return {
    type: tempDocumentActionTypes.DELETE_FILE_REQUEST,
  };
};

export const deleteFileSuccess = (payload) => {
  return {
    type: tempDocumentActionTypes.DELETE_FILE_SUCCESS,
    payload,
  };
};

export const deleteFileFailure = (payload) => {
  return {
    type: tempDocumentActionTypes.DELETE_FILE_FAILURE,
    payload,
  };
};
