import { create } from "axios";
import { store } from "../store/store";
import { decrypt, encrypt } from "./encryption";

const axiosConfig = create();

axiosConfig.defaults.headers.common["Authkey"] = process.env.REACT_APP_AUTH_KEY;
axiosConfig.defaults.headers.common["Content-Type"] = "application/json";

axiosConfig.interceptors.request.use(
  (request) => {
    const { baseType } = request;

    if (baseType === "post") {
      request.baseURL = process.env.REACT_APP_API_POST_URL;
      request.headers.common["Authkey"] = process.env.REACT_APP_AUTH_KEY2;
    } else {
      request.baseURL = process.env.REACT_APP_API_URL;
    }

    request.data = {
      string: encrypt(
        JSON.stringify({ ...request?.data, type: "webView" }),
        "ChennaiZone"
      ),
      required: baseType === "post" ? 1 : 0,
    };
    request.headers.token = store.getState()?.user?.token;
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use((response) => {
  if (response?.data?.status === 1) {
    if (
      typeof response?.data?.responsedata === "string" &&
      typeof response?.data?.responsedata !== "object"
    ) {
      try {
        const decryptedRes = JSON.parse(
          decrypt(response?.data?.responsedata || "", "ChennaiZone")
        );
        response.data.responsedata = decryptedRes;
      } catch (er) {}
    }
  }
  return response;
});

export default axiosConfig;
