import React, { useEffect, useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

let refsRef;
const Chat = () => {
  const tawkMessengerRef = useRef();

  const onLoad = () => {
    refsRef = tawkMessengerRef.current;
    console.log(refsRef)
  };

  useEffect(
    () => {
      refsRef?.showWidget()
      return () => {
        refsRef?.hideWidget();
      }
    },
    [refsRef]
  );

  return (
    <div>
      <TawkMessengerReact
        propertyId="64b006cccc26a871b028327d"
        widgetId="1hl38da90"
        ref={tawkMessengerRef}
        onLoad={onLoad}
      />
    </div>
  );
};

export default Chat;
