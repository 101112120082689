import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDashboard } from "../../services/DashboardService";
import CustomTable from "../../components/CustomTable/CustomTable";
import DashboardDetails from "../../components/DashboardCards/DashboardDetails";
import { CircularProgress } from "@mui/material";
import BlogList from "../Blog/BlogList";
import { useNavigate } from "react-router-dom";
import headerKeys from "./latestEventHeaderKeys";
import {
  GetEvents,
  GetOngoingEvent,
  ShowEvents,
} from "../../services/EventService";
import moment from "moment";
import { filterEventsByDateRange } from "../../utils/utility";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboard = useSelector((state) => state?.dashboard);
  const ongoingEvents = useSelector((state) => state.event.ongoingEvents);
  const getEvents = useSelector((state) => state.event);

  useEffect(() => {
    dispatch(GetDashboard());
    dispatch(GetOngoingEvent());
    dispatch(GetEvents());
  }, [dispatch]);

  const handleShowEventDetails = (event_id) => {
    navigate(`/taskDetail/${event_id.event_id}`);
    dispatch(ShowEvents(event_id));
  };

  const currentDate = moment();
  const startDate = currentDate.clone().subtract(1, "month");
  const endDate = currentDate.clone().add(1, "month");

  // Filter events based on the date range
  const filteredEvents = filterEventsByDateRange(
    getEvents?.data || [],
    startDate,
    endDate
  );

  return (
    <div className="dashboardWrapper">
      <div className="dashboard_wrapper">
        <ul className="prepration">
          <DashboardDetails
            faIconClassName="fa fa-tasks font-size-h3 text-primary"
            records={
              ongoingEvents.length
                ? ongoingEvents[0]?.jobcode?.job_code_name
                : "No Recent Task"
            }
            title="Recent Task"
            loading={dashboard?.loading}
          />
          <DashboardDetails
            faIconClassName="fa fa-clock font-size-h3 text-primary"
            records={
              ongoingEvents.length ? (
                <>
                  {ongoingEvents[0]?.reviwer?.first_name +
                    " " +
                    ongoingEvents[0]?.reviwer?.last_name}
                  <p>{ongoingEvents[0]?.event_duedate?.split(" ")[0]}</p>
                </>
              ) : (
                "No Reviewer"
              )
            }
            title="Reviewer"
            loading={dashboard?.loading}
          />
        </ul>
      </div>      
      {filteredEvents?.length > 0 ? (
        <div className="dashboard_wrapper">
          {!dashboard?.loading ? (
            <div className="table_box">
              <h4>Recent Tasks</h4>
              <CustomTable
                data={filteredEvents}
                headerKeys={headerKeys(handleShowEventDetails)}
              />
            </div>
          ) : (
            <CircularProgress disableShrink size="45vh" color="secondary" />
          )}
        </div>
      ) : (
        ""
      )}
      <div className="dashboard_wrapper m-0">
        <BlogList />
      </div>
    </div>
  );
};

export default Dashboard;
