import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { onMessage, getMessaging } from "firebase/messaging";
import { firebaseApp } from "../utils/firebase";
import { notificationReceivedFromFirebase } from "../store/actions/notificationActions";
import { store } from "../store/store";

const fireBgNofiChannel = new BroadcastChannel("fb_newMessage");

fireBgNofiChannel.addEventListener("message", (payload) => {
  store.dispatch(notificationReceivedFromFirebase());
});

const useNotification = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const messaging = getMessaging(firebaseApp);
    onMessage(messaging, () => {
      dispatch(notificationReceivedFromFirebase());
    });
  }, [dispatch]);
};

export default useNotification;
