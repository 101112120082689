import { Button } from "@mui/material";

export const CancelBtn = ({ children, ...props }) => {
  return (
    <Button
      color="error"
      variant="contained"
      sx={{ color: "white" }}
      {...props}
    >
      {children}
    </Button>
  );
};
