import { Button } from "@mui/material";

export const PrimaryBtn = ({ children, ...props }) => {
  return (
    <Button variant="contained" {...props}>
      {children}
    </Button>
  );
};

export const PrimaryLoadingBtn = ({ children, loading, ...props }) => {
  return (
    <Button disabled={loading} variant="contained" {...props}>
      {loading ? (
        <span>
          <i className="fa fa-spin fa-spinner mr-2" /> Please Wait...
        </span>
      ) : (
        <>{children}</>
      )}
    </Button>
  );
};
