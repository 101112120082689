import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { uniqueId } from "lodash";
import RandomSkeleton from "../LoadingSpinner/RandomSkeleton";

import styles from "./CustomTable.module.css";

const CustomTable = (props) => {
  const { headerKeys, data, loading } = props;

  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table stickyHeader aria-label="tABLE" className="contetn_table">
        <TableHead>
          <TableRow hover>
            {(headerKeys || []).map((h) => {
              let uid = uniqueId("headerCell");
              return (
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    background: "rgb(57 142 220 )",
                    color: "white",
                    borderBottom: "1.5px solid lightgray",
                  }}
                  key={uid}
                  align="center"
                >
                  {h?.name || ""}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            Array.from({ length: 3 }, (v, i) => i + 1).map((v) => {
              let skWrapperuid = uniqueId("SkeletonWrapper");

              return (
                <TableRow key={skWrapperuid + "Skeleton"}>
                  {
                    //
                    (headerKeys || [])?.map((i) => {
                      let skCelluid = uniqueId("SkeletonCell");
                      return (
                        <TableCell
                          key={skCelluid + "Skeleton"}
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <RandomSkeleton />
                        </TableCell>
                      );
                    })
                    //
                  }
                </TableRow>
              );
            })}
          {!loading &&
            (Array.isArray(data) ? data : []).map((row) => {
              let rowUid = uniqueId("mainRow");

              return (
                <TableRow
                  hover
                  key={rowUid}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  {(Array.isArray(headerKeys) ? headerKeys : []).map((cell) => {
                    let uid = uniqueId("rowCell");
                    return (
                      <TableCell
                        key={uid + cell?.name}
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {cell.formatter
                          ? cell.formatter(row)
                          : row[cell?.key] || ""}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
