import { memo, useCallback, useEffect, useMemo, useState } from "react";
import DataGrid from "react-data-grid";

import { TablePagination } from "@mui/material";

import { loadingRowGenerator } from "./loadingCellGenerator";

import notFoundImage from "../../assets/images/norecords.png";
import "./DataGrid.css";

const CustomDataGrid = ({
  headerKeys = [],
  dataRows = [],
  keyName,
  searchKeys,
  filterBtn,
  loading,
}) => {
  const [rows, setRows] = useState(dataRows);
  const [sortColumns, setSortColumns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pagination, setPagination] = useState({
    display_per_page: 10,
    page: 0,
  });

  useEffect(() => {
    setRows(dataRows);
  }, [dataRows]);

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return rows;
    const sorted = [...rows].sort((a, b) => {
      if (a[sortColumns[0]?.columnKey] < b[sortColumns[0]?.columnKey]) {
        return sortColumns[0]?.direction === "ASC" ? -1 : 1;
      }
      if (a[sortColumns[0]?.columnKey] > b[sortColumns[0]?.columnKey]) {
        return sortColumns[0]?.direction === "ASC" ? 1 : -1;
      }
      return 0;
    });
    return sorted;
  }, [rows, sortColumns]);

  const rowKeyGetter = useCallback(
    (row) => {
      return row[loading ? "id" : keyName];
    },
    [keyName, loading]
  );

  const filteredRows = useMemo(() => {
    if (!searchTerm || !searchKeys) return sortedRows;
    let searchKey = searchKeys;
    if (Array.isArray(searchKeys)) {
      return sortedRows.filter((row) => {
        let res = false;
        for (let i = 0; i < searchKeys.length; i++) {
          if (
            (row[searchKeys[i]] || "")
              .toLowerCase()
              .includes((searchTerm || "").toLowerCase())
          ) {
            res = true;
            break;
          } else {
            res = false;
          }
        }
        return res;
      });
    } else {
      return sortedRows.filter((row) => {
        return (row[searchKey] || "")
          .toLowerCase()
          .includes((searchTerm || "").toLowerCase());
      });
    }
  }, [sortedRows, searchKeys, searchTerm]);

  const loadingRows = useMemo(() => {
    return loadingRowGenerator(headerKeys);
  }, [headerKeys]);

  const paginatedRows = useMemo(() => {
    let perPage = pagination.display_per_page;
    let pageNum = pagination.page;
    let pointerStart = pageNum === 0 ? 0 : pageNum * perPage;
    let pointerEnd = pageNum === 0 ? perPage : pageNum * perPage + perPage;
    const paginated = filteredRows.slice(pointerStart, pointerEnd);
    return paginated;
  }, [pagination, filteredRows]);

  // ----------------
  // GRID ELEMENT
  // ---------------
  const gridElement = useMemo(
    () => (
      <DataGrid
        rowHeight={45}
        headerRowHeight={50}
        rowKeyGetter={rowKeyGetter}
        columns={headerKeys}
        rows={loading ? loadingRows : paginatedRows}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
      />
    ),
    [rowKeyGetter, headerKeys, paginatedRows, sortColumns, loading, loadingRows]
  );

  // PAGINATION

  const onPageChange = useCallback((e, value) => {
    setPagination((prev) => ({ ...prev, page: parseInt(value) }));
  }, []);

  const onRowsPerPageChange = useCallback((e) => {
    setPagination((prev) => ({
      ...prev,
      display_per_page: parseInt(e.target.value),
    }));
  }, []);

  const handleSearchInputChange = useCallback((value) => {
    setSearchTerm(value);
  }, []);
  //   --------------------
  // MAIN RETURN
  // ------------------

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 0.3em",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div className="upload_btn"> {filterBtn}</div>
      </div>
      {<div className="contetn_table">{gridElement}</div>}
      {!loading && paginatedRows?.length <= 0 && (
        <div
          style={{
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={notFoundImage}
            alt="No Records found"
            style={{ width: "30vh", height: "30vh", padding: 0, margin: 0 }}
          />
          <span>
            <i className="fa fa-times-circle text-danger"></i>
            <span style={{ fontSize: "0.9em", fontWeight: "bold" }}>
              &nbsp;No Records Found...
            </span>
          </span>
        </div>
      )}
      <div className="mt-2">
        <TablePagination
          component="div"
          count={filteredRows.length || 0}
          page={pagination.page}
          onPageChange={onPageChange}
          rowsPerPage={pagination.display_per_page}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </div>
    </div>
  );
};

export default CustomDataGrid;

const SearchInput = memo(
  ({ searchTerm, onSearchInputChange, searchPlaceholder }) => {
    const handleChange = (e) => {
      onSearchInputChange && onSearchInputChange(e.target.value);
    };
    return (
      <input
        value={searchTerm}
        onChange={handleChange}
        type="search"
        placeholder={searchPlaceholder || "Search"}
        id="data-search"
        name="data-search"
      />
    );
  }
);
