import { Divider, LinearProgress, List, ListItem } from "@mui/material";
import React, { Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import getUserNotifications from "../../services/NotificationService";

const NotificationList = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification);

  useEffect(() => {
    dispatch(getUserNotifications());
  }, [dispatch]);

  const notificationList = useMemo(
    () =>
      (notifications?.data?.details || []).map((noti) => {
        return (
          <Fragment key={noti?.notification_id}>
            <ListItem li="true">
              <div className="w-100">
                <div className="d-flex justify-content-between">
                  {/* TitleDiv */}
                  <div>
                    <small>
                      <b>{noti.template_sub}</b>&nbsp;
                    </small>
                  </div>
                </div>
                <small>
                  {noti.template_body}
                  {/* {noti.template_body.split("-").splice(1).join("-")} */}
                </small>
              </div>
            </ListItem>

            <Divider />
          </Fragment>
        );
      }),
    [notifications?.data?.details]
  );
  return (
    <List
      style={{ padding: 0, margin: 0 }}
      component="ul"
      aria-label="notifications"
      className="css-h4y409-MuiList-root"
    >
      {notifications?.loading ? (
        <LinearProgress color="secondary" />
      ) : (
        <Divider />
      )}
      {notifications?.data?.details &&
      notifications.data?.details.length > 0 ? (
        <>{notificationList}</>
      ) : (
        <ListItem className="pl-6 pr-6 pb-4 pt-4">
          No Notifications....
        </ListItem>
      )}
    </List>
  );
};

export default NotificationList;
