import { LinearProgress } from "@mui/material";
import { Divider, List, ListItem } from "@mui/material";
import { useEffect, useMemo, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import getUserNotifications from "../services/NotificationService";
import { Link } from "react-router-dom";

const NotificationsPopup = ({ handleClose }) => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification);

  useEffect(() => {
    dispatch(getUserNotifications());
  }, [dispatch]);

  const notificationList = useMemo(
    () =>
      (notifications?.data?.details || [])
        .slice(0, 5)
        .map((noti) => {
          return (
            <Fragment key={noti?.notification_id}>
              <ListItem
                li="true"
                style={{
                  background: noti.seen === "no" && "aliceblue",
                  borderRadius: "5px",                  
                }}                
              >
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <div>
                      <small>
                        <b>{noti.template_sub}</b>&nbsp;
                      </small>
                    </div>
                  </div>
                  <small>
                    {noti.template_body}
                  </small>
                </div>
              </ListItem>
              <Divider />
            </Fragment>
          );
        }),
    [notifications?.data?.details]
  );

  return (
    <List
      style={{ padding: 0, margin: 0 }}
      component="ul"
      aria-label="notifications"
      className="notification_div css-h4y409-MuiList-root"
    >
      <ListItem className="justify-content-center">
        <b>Notifications</b>&nbsp;
      </ListItem>
      {notifications?.loading ? (
        <LinearProgress color="secondary" />
      ) : (
        <Divider />
      )}
      {notifications?.data?.details &&
      notifications.data?.details.length > 0 ? (
        <>
          {notificationList}
          <ListItem div="true">
            <Link
              to="/viewAllNotifications"
              className="com_btn"
              onClick={handleClose}
            >
              View All
            </Link>
          </ListItem>
        </>
      ) : (
        <ListItem className="pl-6 pr-6 pb-4 pt-4">
          No Notifications....
        </ListItem>
      )}
    </List>
  );
};

export default NotificationsPopup;
