import { toast } from "react-toastify";
import { loginSuccess } from "../store/actions/authActions";
import axiosConfig from "../utils/axiosConfig";
import { requestForToken } from "../utils/firebase";

const login = (data = {}) => {
  return async (dispatch) => {
    const token = await requestForToken().catch((err) =>
      console.log("Failed to retrieve token: ", err)
    );

    if (!data?.email || !data?.password) {
      return;
    }

    const { email, password } = data;

    return axiosConfig
      .post("/client/login", { email, password, fcm_token: token })
      .then((res) => {
        if (res?.data?.status === 1) {
          dispatch(loginSuccess(res?.data?.responsedata));
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch(() => {
        toast?.error("Error Logging in!");
      });
  };
};

export default login;
