import React from "react";
import PrivacyPolicy from "../../pages/Setting/PrivacyPolicy";
import logo_header from "../../assets/images/logo_header.png";
import playstore from "../../assets/images/playstore.png";
import appstore from "../../assets/images/appstore.png";
import TermsCondition from "../../pages/Setting/TermsCondition";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row ">
          <div className="col-md-6 col-sm-6">
            <div className="footer_logo">              
                <img src={logo_header} alt="Logo" />              
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="ft_link">
              <PrivacyPolicy />
              <TermsCondition />
            </div>
            <div className="app_img">
              <a href="https://play.google.com/store/apps/details?id=com.aacon">
                <img src={playstore} alt="playstore" />
              </a>
              <a href="https://apps.apple.com/us/app/aacon/id6473244988">
                <img src={appstore} alt="appstore" />
              </a>
            </div>
          </div>
        </div>
        <p>
          Copyright © 2024 All rights reserved by |<b> Aacon.io</b>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
