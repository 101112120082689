import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import login  from "../services/AuthService";

let tempTimer;

const useAuth = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);

  const [isLoggedIn, setIsLoggedIn] = useState(user?.token ? true : false);
  useEffect(() => {
    setIsLoggedIn(user?.token ? true : false);
    return () => tempTimer && clearTimeout(tempTimer);
  }, [user?.token]);

  const handleLogin = (formData) => {
    setLoading(true);
    dispatch(login(formData)).finally(
      () => (tempTimer = setTimeout(() => setLoading(false), 500))
    );
  };

  return { handleLogin, loading, isLoggedIn };
};

export default useAuth;
