import { directoryActionTypes } from "../actionTypes/directoryActionTypes";

export const directoryListRequest = () => {
  return {
    type: directoryActionTypes.DIRECTORY_LIST_REQUEST,
  };
};

export const directoryListSuccess = (data) => {
  return {
    type: directoryActionTypes.DIRECTORY_LIST_SUCCESS,
    payload: data,
  };
};

export const directoryListFailure = (error) => {
  return {
    type: directoryActionTypes.DIRECTORY_LIST_FAILURE,
    payload: error,
  };
};
