export const EventActionTypes = {
  // All events
  GET_EVENTS_REQUEST: "GET_EVENTS_REQUEST",
  GET_EVENTS_SUCCESS: "GET_EVENTS_SUCCESS",
  GET_EVENTS_FAILURE: "GET_EVENTS_FAILURE",

  //  Get All Event Status
  GET_EVENT_STATUS_REQUEST: "GET_EVENT_STATUS_REQUEST",
  GET_EVENT_STATUS_SUCCESS: "GET_EVENT_STATUS_SUCCESS",
  GET_EVENT_STATUS_FAILURE: "GET_EVENT__STATUS_FAILURE",

  //  Get All Ongoing Event
  GET_ONGOING_EVENT_REQUEST: "GET_ONGOING_EVENT_REQUEST",
  GET_ONGOING_EVENT_SUCCESS: "GET_ONGOING_EVENT_SUCCESS",
  GET_ONGOING_EVENT_FAILURE: "GET_ONGOING_EVENT_FAILURE",

  //  Get Show Event
  GET_SHOW_EVENT_REQUEST: "GET_SHOW_EVENT_REQUEST",
  GET_SHOW_EVENT_SUCCESS: "GET_SHOW_EVENT_SUCCESS",
  GET_SHOW_EVENT_FAILURE: "GET_SHOW_EVENT_FAILURE",
};
