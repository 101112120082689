import { uniqueId } from "lodash";
import RandomSkeleton from "../LoadingSpinner/RandomSkeleton";

export const loadingRowGenerator = (headerKeys) => {
  const rows = {};

  headerKeys.forEach((header, idx) => {
    rows[header.key] = (
      <RandomSkeleton key={header.key + "LoadingSkeleton" + idx} />
    );
  });

  return Array.from({ length: 4 }).map(() => ({
    ...rows,
    id: uniqueId("loaderSkeleton"),
  }));
};
