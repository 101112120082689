import { uiActionTypes } from "../actionTypes/uiActionTypes";

// Navigation Sidebar Menu
export const showNavigationMenu = () => ({
  type: uiActionTypes.SHOW_NAVIGATION_MENU,
});
export const hideNavigationMenu = () => ({
  type: uiActionTypes.HIDE_NAVIGATION_MENU,
});
export const toggleNavigationMenu = () => ({
  type: uiActionTypes.TOGGLE_NAVIGATION_MENU,
});
