import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Authentication/Login";
import Layout from "../components/Layout/Layout.jsx";
import TempDocumentList from "../pages/TempDocument/TempDocumentList";
import ContactList from "../pages/Contact/ContactList";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword.js";
import DocumentUpload from "../pages/UploadDocument/DocumentUpload.js";
import DirectoryAccess from "../pages/FileDirectory/DirectoryAccess.js";
import NotificationList from "../pages/Notification/NotificationList.js";
import Blogs from "../pages/Blog/Blogs.js";
import ShowEventDialog from "../pages/Event/ShowEventDialog.js";

const MakeLayoutComponent = ({ Component, title }) => {
  return (
    <Layout title={title}>
      <Component />
    </Layout>
  );
};

const routes = [
  {
    path: "/login",
    title: "Login To Aacon",
    Component: Login,
    isAuthenticated: false,
    includeLayout: false,
  },
  {
    path: "/forgotPassword",
    title: "Forgot Password",
    Component: ForgotPassword,
    isAuthenticated: false,
    includeLayout: false,
  },
  {
    path: "/",
    title: "Dashboard",
    Component: (
      <MakeLayoutComponent title={"Dashboard"} Component={Dashboard} />
    ),
    isAuthenticated: true,
    includeLayout: true,
  },
  {
    path: "/viewAllNotifications",
    title: "Notification List",
    Component: (
      <MakeLayoutComponent
        title={"Notification List"}
        Component={NotificationList}
      />
    ),
    isAuthenticated: true,
    includeLayout: true,
  },
  {
    path: "/taskDetail/:event_id",
    title: "Task Detail",
    Component: (
      <MakeLayoutComponent title="Task Detail" Component={ShowEventDialog} />
    ),
    isAuthenticated: true,
    includeLayout: true,
  },
  {
    path: "/blogList",
    title: "Blog List",
    Component: <MakeLayoutComponent title="Blog List" Component={Blogs} />,
    isAuthenticated: true,
    includeLayout: true,
  },
  {
    path: "/uploadDocument",
    title: "Document Upload",
    Component: (
      <MakeLayoutComponent
        title={"Document Upload"}
        Component={DocumentUpload}
      />
    ),
    isAuthenticated: true,
    includeLayout: true,
  },
  {
    path: "/tempDocuments",
    title: "Temporary Files",
    Component: <MakeLayoutComponent Component={TempDocumentList} />,
    isAuthenticated: true,
    includeLayout: true,
  },
  {
    path: "/directoryAccess",
    title: "Directory Access",
    Component: (
      <MakeLayoutComponent
        title={"Directory Access"}
        Component={DirectoryAccess}
      />
    ),
    isAuthenticated: true,
    includeLayout: true,
  },
  {
    path: "/contacts",
    title: "Contacts",
    Component: <MakeLayoutComponent Component={ContactList} />,
    isAuthenticated: true,
    includeLayout: true,
  },
];

export default routes;
