import { CircularProgress } from "@mui/material";
import { Skeleton } from "@mui/material";

export const DashboardSpinner = () => {
    return (
      <>
        <div className="d-flex align-items-center p-4">
          <CircularProgress color="primary" className="mr-4 ml-2" />
          <div className="flex-grow-1">
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
            <Skeleton animation="wave" />
            <Skeleton />
          </div>
        </div>
      </>
    );
  };