import { tempDocumentActionTypes } from "../actionTypes/tempDocumentActionTypes";

const initialState = {
  loading: false,
  data: "",
  error: "",
  fileUpload: {
    uploading: false,
    data: "",
    error: "",
  },
};

export const tempDocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    // List
    case tempDocumentActionTypes.TEMP_DOCUMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case tempDocumentActionTypes.TEMP_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };

    case tempDocumentActionTypes.TEMP_DOCUMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        error: action.payload,
      };

    // Upload File
    case tempDocumentActionTypes.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        fileUpload: {
          ...state.fileUpload,
          uploading: true,
          error: "",
        },
      };

    case tempDocumentActionTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fileUpload: {
          ...state.fileUpload,
          uploading: false,
          data: action.payload,
          error: "",
        },
      };

    case tempDocumentActionTypes.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        fileUpload: {
          ...state.fileUpload,
          uploading: false,
          error: action.payload,
        },
      };

    // Delete File
    case tempDocumentActionTypes.DELETE_FILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case tempDocumentActionTypes.DELETE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };

    case tempDocumentActionTypes.DELETE_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
