import { toast } from "react-toastify";
import {
  getBlogsFailure,
  getBlogsRequest,
  getBlogsSuccess,
} from "../store/actions/blogActions";
import axiosConfig from "../utils/axiosConfig";

const BlogService = (data) => {
  return (dispatch) => {
    dispatch(getBlogsRequest());

    return axiosConfig({
      method: 'post',
      url: '/posts',
      baseType: 'post',
      data
    })
      .then((res) => {
        if (res.data?.code === "success") {
          dispatch(getBlogsSuccess(res.data?.data));
        } else {
          dispatch(getBlogsFailure(res.data?.message));
        }
      })
      .catch((err) => {
        toast.error("Error Fetching Blogs....");
        dispatch(getBlogsFailure(err.message));
      });
  };
};

export default BlogService;
