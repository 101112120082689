import { createTheme } from "@mui/material";

export const mainPrimaryColor = "#253B5F";
export const mainLightColor = "#C8E3D4";
export const mainDarkColor = "#042f2f";

export const SecondaryPrimaryColor = "#e2d7b0";
export const SecondaryLightColor = "#F6EABE";
export const SecondaryDarkColor = "#F6D7A7";

export const themeOptions = createTheme({
  palette: {
    type: "light",
    primary: {
      main: mainPrimaryColor,
      light: mainLightColor,
      dark: mainDarkColor,
    },
    secondary: {
      main: SecondaryPrimaryColor,
      light: SecondaryLightColor,
      dark: SecondaryDarkColor,
    },
    error: {
      main: "#f44336",
      light: "#2b0113",
      contrastText: "#960707",
    },
  },
  typography: {
    fontFamily: "Slabo 27px",
  },
});
