import { uiActionTypes } from "../actionTypes/uiActionTypes";

const initialState = {
  sidebarMenu: false,
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case uiActionTypes.SHOW_NAVIGATION_MENU:
      return {
        ...state,
        sidebarMenu: true,
      };
    case uiActionTypes.HIDE_NAVIGATION_MENU:
      return {
        ...state,
        sidebarMenu: false,
      };
    case uiActionTypes.TOGGLE_NAVIGATION_MENU:
      return {
        ...state,
        sidebarMenu: !state?.sidebarMenu,
      };
    default:
      return state;
  }
};
