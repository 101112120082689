import { useCallback } from "react";

export const useFileUtilities = () => {
  const getFileExtension = useCallback((fileUrl) => {
    return typeof fileUrl === "string"
      ? fileUrl.split(".").pop().toLowerCase()
      : "";
  }, []);

  const getFileIconClass = useCallback(
    (file) => {
      const fileExtension = getFileExtension(file);
      switch (fileExtension) {
        case "pdf":
          return "fa fa-file-pdf text-danger";
        case "doc":
        case "docx":
          return "fa fa-file-word text-primary";
        case "pptx":
          return "fa fa-file-pdf text-danger";
        case "xlsx":
        case "xls":
        case "csv":
          return "fa fa-file-excel text-success";
        case "jpg":
        case "jpeg":
        case "png":
          return "fa fa-file-image text-success";
        default:
          return "fa fa-file";
      }
    },
    [getFileExtension]
  );

  const handleDownloadFileBtn = useCallback((fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileName);
    link.setAttribute("target", "+_blank");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, []);

  return { getFileIconClass, handleDownloadFileBtn };
};
