import React, { useCallback, useEffect, useMemo } from "react";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess } from "../../store/actions/authActions";
import { toggleNavigationMenu } from "../../store/actions/uiActions";
import styles from "./Header.module.css";
import ProfileMenu from "./ProfileMenu";
import logo_header from "../../assets/images/logo.png";
import axiosConfig from "../../utils/axiosConfig";
import { sidebarLinks } from "../NavigationSidebar/sidebarLinks";
import { Link, useLocation } from "react-router-dom";

const Header = (props) => {
  const { user, notifications, getUserNotifications } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const users = useSelector((state) => state.user);

  useEffect(() => {
    axiosConfig.defaults.headers.common["token"] = user?.token;
    if (user?.token) {
      getUserNotifications({
        display_per_page: notifications?.display_per_page,
        page_number: 1,
      });
    }
    // eslint-disable-next-line
  }, [user?.token]);

  const toggleDrawer = useCallback(() => {
    dispatch(toggleNavigationMenu());
  }, [dispatch]);

  const handleLogout = useCallback(() => {
    dispatch(logoutSuccess());
  }, [dispatch]);

  const filteredLinks = useMemo(() => {
    if (users?.client_type === "Personal") {
      return sidebarLinks.filter((link) => link.path !== "/contacts");
    }
    return sidebarLinks;
  }, [users?.client_type]);

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "white",
        boxShadow: "none",
      }}
      className="css-1w90l2p-MuiPaper-root-MuiAppBar-root"
    >
      <Toolbar className={styles.toolbar}>
        <div className="mobile_div">
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
            className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit MuiIconButton-edgeStart MuiIconButton-sizeMedium css-1d6w9lk-MuiButtonBase-root-MuiIconButton-root"
          >
            <i className="fa fa-bars" />
          </IconButton>
          <a href="/">
            <img src={logo_header} className="mobile_logo" alt="Logo" />
          </a>
        </div>
        <div className="manu_bar">
          <ul
            className={
              styles.menuLinksWrapper +
              " NavigationSidebar_menuLinksWrapper__1bsNA"
            }
          >
            {(filteredLinks || []).map((l) => {
              const isCurrent = location?.pathname === l.path;
              return (
                <li key={l.path}>
                  <Link
                    className={
                      styles.menuLink +
                      " " +
                      (isCurrent ? styles.menuLinkSelected : "")
                    }
                    to={l.path}
                  >
                    {l.iconClass && (
                      <i
                        className={
                          l.iconClass +
                          " " +
                          (isCurrent
                            ? styles.menuIconSelected
                            : styles.menuIcon)
                        }
                        style={{
                          marginRight: "0.5rem",
                        }}
                      />
                    )}
                    {l.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <ProfileMenu
          handleLogoutBtn={handleLogout}
          notifications={notifications}
          getUserNotifications={getUserNotifications}
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
