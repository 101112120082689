import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <a href={"https://aacon.io/privacy-policy/"}>Privacy Policy</a>
    </div>
  );
};

export default PrivacyPolicy;
