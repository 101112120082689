const ChangePassword = ({
  data,
  passwordsInput,
  setPasswordsInput,
  handleChangePassword,
  validatePasswordInput,
}) => {
  return (
    <>
      <h1 className="text-black">Reset Password</h1>
      {/* <p className="fw-medium text-muted">Enter New Password</p> */}
      <div className="col-md-12 col-xl-12 ">
        <div className="form-group">
          <input
            type="password"
            className={
              "form-control form-control-lg py-4" +
              (passwordsInput.passwordError && passwordsInput.submittedOnce
                ? " is-invalid"
                : "")
            }
            value={passwordsInput.password}
            onChange={(e) => {
              validatePasswordInput({
                confirm_password: passwordsInput.confirm_password,
                password: e.target.value || "",
              });
              setPasswordsInput((prev) => ({
                ...prev,
                password: e.target.value,
              }));
            }}
            id="password"
            name="password"
            placeholder="New Password"
          />
          {passwordsInput.passwordError && passwordsInput.submittedOnce && (
            <span className="help-block invalid-feedback fadeIn">
              {passwordsInput.passwordError}
            </span>
          )}
        </div>
        <div className="form-group">
          <input
            type="password"
            className={
              "form-control form-control-lg py-4" +
              (passwordsInput.confirm_passwordError &&
              passwordsInput.submittedOnce
                ? " is-invalid"
                : "")
            }
            value={passwordsInput.confirm_password}
            onChange={(e) => {
              validatePasswordInput({
                confirm_password: e.target.value || "",
                password: passwordsInput.password,
              });
              setPasswordsInput((prev) => ({
                ...prev,
                confirm_password: e.target.value,
              }));
            }}
            id="confirm_password"
            name="confirm_password"
            placeholder="Confirm New Password"
          />
          {passwordsInput.confirm_passwordError &&
            passwordsInput.submittedOnce && (
              <span className="help-block invalid-feedback fadeIn">
                {passwordsInput.confirm_passwordError}
              </span>
            )}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-12 col-xl-12 ">
          <button
            type="button"
            onClick={handleChangePassword}
            className="btn btn-alt-primary float-right"
            disabled={data?.changePassLoading}
          >
            {!data?.changePassLoading ? (
              <>
                <i className="fa fa-fw fa-key opacity-50">&nbsp;</i>
                Change Password
              </>
            ) : (
              <>
                <i className="fas fa-spinner fa-spin" />
                &nbsp;Loading...
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
