import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosConfig from "../utils/axiosConfig";

const otpSendInitialState = {
  otpSendLoading: false,
  otpSendRequested: false,
  otpSent: false,
  otpSendError: "",
  emailNotFound: false,
  otpSendMessage: "",
};

const checkOtpInitialState = {
  checkOtpLoading: false,
  checkOtpRequested: false,
  otpIsValid: false,
  checkOtpError: "",
};

const changePasswordInitialState = {
  changePassLoading: false,
  changePasswordRequested: false,
  changePassSuccess: false,
  changePassError: "",
};

const initialState = {
  ...otpSendInitialState,
  ...checkOtpInitialState,
  ...changePasswordInitialState,
  section: "email",
};

let otpInterval;

const useForgotPassword = () => {
  const [data, setData] = useState(initialState);
  const [otpTimer, setOtpTimer] = useState(60);

  const resetOtpInterval = () => {
    if (otpInterval) {
      clearInterval(otpInterval);
      setOtpTimer(60);
    }
  };

  useEffect(() => {
    if (otpTimer <= 0) {
      clearInterval(otpInterval);
    }
  }, [otpTimer]);

  const getOtp = (email) => {
    setOtpTimer(60);
    // resetOtpInterval();

    setData((prev) => ({
      ...prev,
      otpSendLoading: true,
      otpSendRequested: true,
    }));
    axiosConfig
      .post("/client/forgotPassword", { email })
      .then((response) => {
        if (response.data.status === 1) {
          otpInterval = setInterval(() => {
            setOtpTimer((prev) => prev - 1);
          }, 1000);
          setData((prev) => ({
            ...prev,
            otpSendLoading: false,
            otpSent: true,
            otpSendError: "",
            emailNotFound: false,
            otpSendMessage: "OTP sent successfully!",
            section: "otp",
          }));
        } else {
          setData((prev) => ({
            ...prev,
            otpSendLoading: false,
            otpSent: false,
            otpSendError: response?.data?.message,
            emailNotFound: false,
            otpSendMessage: response?.data?.message,
          }));
          toast.error(response?.data?.message || "User Not found!", {
            position: "bottom-right",
            autoClose: 3000,
            closeOnClick: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        resetOtpInterval();
        setData((prev) => ({
          ...prev,
          otpSendLoading: false,
          otpSent: false,
          otpSendError: "Error! Sending OTP- Forgot Password",
          emailNotFound: false,
          otpSendMessage: "Error! Sending OTP",
        }));
      });
  };

  const verifyOtp = (email, otp) => {
    resetOtpInterval();
    setData((prev) => ({
      ...prev,
      checkOtpLoading: true,
      checkOtpRequested: true,
    }));
    axiosConfig
      .post("/client/verifiedOTP", { email, otp })
      .then((response) => {
        if (response.data.status === 1) {
          setData((prev) => ({
            ...prev,
            checkOtpLoading: false,
            otpIsValid: true,
            checkOtpError: "",
            section: "password",
          }));
        } else {
          setData((prev) => ({
            ...prev,
            checkOtpLoading: false,
            otpIsValid: false,
            checkOtpError: response?.data?.message,
          }));
          toast.error(response?.data?.message || "Invalid OTP", {
            position: "bottom-right",
            autoClose: 3000,
            closeOnClick: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        setData((prev) => ({
          ...prev,
          checkOtpLoading: false,
          otpIsValid: false,
          checkOtpError: "Error! Verifying OTP.",
        }));
        toast.error("Error! Verifying OTP.", {
          position: "bottom-right",
          autoClose: 3000,
          closeOnClick: true,
          draggable: true,
          progress: 0,
        });
      });
  };

  const changePassword = (email, otp, password, confirm_password) => {
    setData((prev) => ({
      ...prev,
      changePassLoading: true,
      changePasswordRequested: true,
    }));
    axiosConfig
      .post("/client/updatePassword", { email, otp, password, confirm_password })
      .then((response) => {
        if (response.data.status === 1) {
          setData((prev) => ({
            ...prev,
            changePassLoading: false,
            changePassSuccess: true,
            changePassError: "",
            section: "successfull",
          }));
        } else {
          setData((prev) => ({
            ...prev,
            changePassLoading: false,
            changePassSuccess: false,
            changePassError: response.data.message || true,
          }));
          toast.error(response?.data?.message || "Error! Changing Password.", {
            position: "bottom-right",
            autoClose: 3000,
            closeOnClick: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        setData((prev) => ({
          ...prev,
          changePassLoading: false,
          changePassSuccess: false,
          changePassError: "Error! Changing Password.",
        }));
        toast.error("Error! Changing Password.", {
          position: "bottom-right",
          autoClose: 3000,
          closeOnClick: true,
          draggable: true,
          progress: 0,
        });
      });
  };

  const resetForgotPassword = () => {
    setData(initialState);
  };

  return {
    data,
    getOtp,
    verifyOtp,
    changePassword,
    resetForgotPassword,
    otpTimer,
  };
};

export default useForgotPassword;
