export const tempDocumentActionTypes = {
  // List
  TEMP_DOCUMENT_LIST_REQUEST: "TEMP_DOCUMENT_LIST_REQUEST",
  TEMP_DOCUMENT_LIST_SUCCESS: "TEMP_DOCUMENT_LIST_SUCCESS",
  TEMP_DOCUMENT_LIST_FAILURE: "TEMP_DOCUMENT_LIST_FAILURE",

  // Upload File
  UPLOAD_FILE_REQUEST: "UPLOAD_FILE_REQUEST",
  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_FAILURE: "UPLOAD_FILE_FAILURE",

  // Delete File
  DELETE_FILE_REQUEST: "DELETE_FILE_REQUEST",
  DELETE_FILE_SUCCESS: "DELETE_FILE_SUCCESS",
  DELETE_FILE_FAILURE: "DELETE_FILE_FAILURE",
};
