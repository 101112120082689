import { toast } from "react-toastify";
import {
  getDashboardFailure,
  getDashboardRequest,
  getDashboardSuccess,
} from "../store/actions/DashboardActions";
import axiosConfig from "../utils/axiosConfig";

export const GetDashboard = () => {
  return (dispatch) => {
    dispatch(getDashboardRequest());

    axiosConfig
      .post("/client/dashboard", "")
      .then((res) => {
        if (res.data?.status === 1) {
          dispatch(getDashboardSuccess(res.data?.responsedata?.details));
        } else {
          toast.error(res?.data?.message);
          dispatch(getDashboardFailure(res.data?.message));
        }
      })
      .catch((err) => {
        toast.error("Error Fetching Dashboard Details....");
        dispatch(getDashboardFailure(err));
      });
  };
};
