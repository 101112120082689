import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import routes from "./routesArray";

const RoutesComponent = () => {
  const user = useSelector((state) => state?.user);

  const filteredRoutes = useMemo(() => {
    if (user?.client_type === "Personal") {
      return routes?.filter((route) => route.path !== "/contacts");
    }
    return routes;
  }, [user?.client_type]);

  const routesMap = useMemo(() => {
    // Function to process routes
    const processRoutes = (routes) => {
      return routes
        .map(
          ({
            path,
            Component,
            isAuthenticated,
            title,
            ...props
          }) => {
            let RouteComp = isAuthenticated ? (
              <PrivateRoute {...props} title={title}>
                {Component}
              </PrivateRoute>
            ) : (
              <Component {...props} title={title} />
            );

            let routeElements = [
              <Route
                key={path + title}
                title={title}
                path={path}
                element={RouteComp}
              />,
            ];           

            return routeElements;
          }
        )
        .flat();
    };

    return processRoutes(filteredRoutes || []);
  }, [filteredRoutes]);

  return (
    <Routes>
      {routesMap}
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>page Not Found!</p>
          </main>
        }
      />
    </Routes>
  );
};

export default RoutesComponent;
