import { notificationActionTypes } from "../actionTypes/notificationActionTypes";

// ===================================
// Get All User Notifications
// ===================================

export const getUserNotificationsRequest = (payload) => {
  return {
    type: notificationActionTypes.GET_USER_NOTIFICATIONS_REQUEST,
    payload,
  };
};

export const getUserNotificationsSuccess = (payload) => {
  return {
    type: notificationActionTypes.GET_USER_NOTIFICATIONS_SUCCESS,
    payload,
  };
};

export const getUserNotificationsFailure = (payload) => {
  return {
    type: notificationActionTypes.GET_USER_NOTIFICATIONS_FAILURE,
    payload,
  };
};

export const notificationReceivedFromFirebase = () => {
  return {
    type: notificationActionTypes.NOTIFICATION_RECEIVED,
  };
};

export const resetNewNotifications = () => {
  return {
    type: notificationActionTypes.RESET_NEW_NOTIFICATIONS,
  };
};
