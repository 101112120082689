import axios from "axios";
import { toast } from "react-toastify";
import {
  deleteFileFailure,
  deleteFileRequest,
  deleteFileSuccess,
  tempDocumentListFailure,
  tempDocumentListRequest,
  tempDocumentListSuccess,
  uploadFileFailure,
  uploadFileRequest,
  uploadFileSuccess,
} from "../store/actions/tempDocumentActions";
import axiosConfig from "../utils/axiosConfig";
import { decrypt } from "../utils/encryption";

  export const TempDocumentListing = () => {
    return (dispatch) => {
      dispatch(tempDocumentListRequest());

      axiosConfig
        .post("client/listTempDocument", "")
        .then((res) => {
          if (res?.data?.status === 1) {
            dispatch(tempDocumentListSuccess(res.data?.responsedata?.details));
          } else {
            toast.error(res?.data?.message);
            dispatch(tempDocumentListFailure(res.data?.message));
          }
        })
        .catch((err) => {
          toast.error("Error Fetching Temp Document List....");
          dispatch(tempDocumentListFailure(err));
        });
    };
  };

  // Upload File
  export const UploadFile = (data) => {
    return (dispatch, getState) => {
      dispatch(uploadFileRequest());

      const token = getState()?.user?.token;
      return axios
        .post(
          `${process.env.REACT_APP_API_URL}/client/uploadTempDocument`,
          data,
          {
            headers: {
              token,
              "content-Type": "application/json",
              Authkey: process.env.REACT_APP_AUTH_KEY,
            },
          }
        )
        .then((response) => {
          if (response?.data?.status === 1) {
            response.data.responsedata = JSON.parse(
              decrypt(response?.data?.responsedata || "", "ChennaiZone")
            );
          }

          if (response.data.status === 1) {
            dispatch(uploadFileSuccess(response.data?.responsedata?.details));
            toast.success("File Uploaded Successfully");
          } else {
            dispatch(uploadFileFailure(response.data.message));
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          dispatch(uploadFileFailure(error));
          toast.error("Upload Failed");
        });
    };
  };

  // Delete
  export const DeleteTempDocument = (data) => {
    return (dispatch) => {
      dispatch(deleteFileRequest());

      return axiosConfig
        .post("client/deleteTempDocument", data)
        .then((res) => {
          if (res?.data?.status === 1) {
            dispatch(deleteFileSuccess(res.data?.responsedata?.details));
            return true;
          } else {
            toast.error(res?.data?.message);
            dispatch(deleteFileFailure(res.data?.message));
            return false;
          }
        })
        .catch((err) => {
          toast.error("Error Deleting Temp Document List....");
          dispatch(deleteFileFailure(err));
          return false;
        });
    };
  };

