import { BlogActionTypes } from "../actionTypes/blogActionTypes";

export const getBlogsRequest = () => {
  return {
    type: BlogActionTypes.GET_BLOGS_REQUEST,
  };
};

export const getBlogsSuccess = (payload) => {
  return {
    type: BlogActionTypes.GET_BLOGS_SUCCESS,
    payload,
  };
};

export const getBlogsFailure = (payload) => {
  return {
    type: BlogActionTypes.GET_BLOGS_FAILURE,
    payload,
  };
};
