import moment from "moment";
import axiosConfig from "../utils/axiosConfig";
import {
  getUserNotificationsFailure,
  getUserNotificationsRequest,
  getUserNotificationsSuccess,
} from "../store/actions/notificationActions";

const getUserNotifications = (data) => {
  return (dispatch, getStore) => {
    const store = getStore();
    const lastUpdated = store.notification.lastUpdated;

    if (lastUpdated) {
      let diff = moment().diff(moment(lastUpdated), "seconds");
      if (diff < 40) return;
    }

    dispatch(getUserNotificationsRequest());

    axiosConfig
      .post("/client/getNotifications", data)
      .then((response) => {
        if (!response) return;
        if (response.data.status === 1) {
          dispatch(
            getUserNotificationsSuccess(
              response.data.responsedata,
            )
          );
        } else {
          dispatch(getUserNotificationsFailure(response.data.responsedata));
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        if (parseInt(error.message) !== 1) {
          // toast.error("Error Fetching Notifications");
          dispatch(getUserNotificationsFailure(error));
        }
      });
  };
};

export default getUserNotifications;
