import { DashboardSpinner } from "../Loaders/DashboardSpinner";

function DashboardDetails(props) {
  const { records, title, loading, faIconClassName } = props;

  return (
    <li>
      {loading ? (
        <DashboardSpinner />
      ) : (
        <div className="client_box">
          <i className={faIconClassName}></i>
          <div className="tag_line">
            <span>{title}</span>
            <h6>{records}</h6>
          </div>
        </div>
      )}
    </li>
  );
}

export default DashboardDetails;
