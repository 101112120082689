import { authActionTypes } from "../actionTypes/authActionTypes";

export const loginSuccess = (payload) => ({
  type: authActionTypes.LOGIN_SUCCESS,
  payload,
});

export const logoutSuccess = (payload) => ({
  type: authActionTypes.LOGOUT_SUCCESS,
  payload,
});

export const setNotificationToken = (fcm_token) => ({
  type: authActionTypes.SET_NOTIFICATION_TOKEN,
  payload: fcm_token,
});
