import { useEffect } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  TempDocumentListing,
  DeleteTempDocument,
} from "../../services/TempDocumentService";
import { SecondaryBtn } from "../../components/CustomButtons/SecondaryBtn";
import PageTitle from "../../components/Layout/PageTitle";
import { CircularProgress } from "@mui/material";
import { useFileUtilities } from "../../hooks/useFileUtilities";

const TempDocumentList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getFileIconClass, handleDownloadFileBtn } = useFileUtilities();

  useEffect(() => {
    dispatch(TempDocumentListing());
  }, [dispatch]);

  const tempDocumentList = useSelector((state) => state?.tempDocument);

  const handleDeleteFileBtn = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (willDelete) => {
      if (willDelete.isConfirmed) {
        let deleted = await dispatch(
          DeleteTempDocument({
            client_temp_document_id: data?.client_temp_document_id,
          })
        );
        if (deleted) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {
          Swal.fire("Oops!", "Could not be deleted!.", "error");
        }
      }
    });
  };

  return (
    <>
      <PageTitle title="Temporary Files">
        <SecondaryBtn
          className="com_btn css-82cuxn-MuiButtonBase-root-MuiButton-root"
          type="button"
          size="small"
          onClick={() => navigate("/uploadDocument")}
        >
          <i className="fa fa-file-upload" /> &nbsp; Upload File
        </SecondaryBtn>
      </PageTitle>
      {tempDocumentList?.loading ? (
        <CircularProgress disableShrink size="45vh" color="secondary" />
      ) : (
        <ul className="temp_File">
          {Array.isArray(tempDocumentList?.data) ? (
            tempDocumentList?.data?.map((document) => (
              <li key={document.client_temp_document_id}>
                <h5>
                  <i
                    className={getFileIconClass(document.file_path)}
                    style={{ fontSize: "20px", marginRight: 10 }}
                  ></i>
                  {document.file_name}
                </h5>
                <div className="icon_Btn">
                  <button
                    type="button"
                    className="btn btn-sm deleteBtn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Delete"
                    onClick={() => handleDeleteFileBtn(document)}
                  >
                    <i className="fa fa-trash-alt" />
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-sm downloadBtn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Download"
                    onClick={() =>
                      handleDownloadFileBtn(
                        `https://api.adaptonus.com:8080/storage/ClientDocuments/${document.file_path}`,
                        document.file_name
                      )
                    }
                  >
                    <i className="fa fa-download" />
                  </button>
                </div>
              </li>
            ))
          ) : (
            <li>No documents available.</li>
          )}
        </ul>
      )}
    </>
  );
};

export default TempDocumentList;
