import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SecondaryBtn } from "../../components/CustomButtons/SecondaryBtn";
import CustomDataGrid from "../../components/DataGrid/CustomDataGrid";
import { ContactListing } from "../../services/ContactService";
import AddContactFormDialog from "./AddContactFormDialog";
import PageTitle from "../../components/Layout/PageTitle";

const ContactList = () => {
  const dispatch = useDispatch();

  const contactList = useSelector((state) => state?.contact);
  useEffect(() => {
    dispatch(ContactListing());
  }, [dispatch]);

  const headerKeys = [
    {
      name: "Client Name",
      key: "first_name",
      formatter: (data) =>
        typeof data?.row?.first_name === "string"
          ? `${data?.row?.first_name} ${data?.row?.last_name}`
          : data?.row?.first_name,
    },
    {
      name: "Phone Number",
      key: "phone_number",
    },
    {
      name: "Email",
      key: "email",
    },
    {
      name: "Allow login",
      key: "allow_login",
    },
  ];

  // Add Form Dialog
  const [addFormDialog, setAddFormDialog] = useState({
    open: false,
  });

  const handleOpenFormDialog = () => {
    setAddFormDialog((prev) => ({
      ...prev,
      open: true,
    }));
  };

  const handleCloseFormDialog = () => {
    setAddFormDialog((prev) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <>
      <CustomDataGrid
        filterBtn={
          <PageTitle title="Contacts">
            <SecondaryBtn
              type="button"
              size="small"
              style={{ marginBottom: "0.5em" }}
              onClick={handleOpenFormDialog}
              className="com_btn css-82cuxn-MuiButtonBase-root-MuiButton-root"
            >
              <i className="fa fa-plus-circle" />
              &nbsp; Add Contact
            </SecondaryBtn>
          </PageTitle>
        }
        headerKeys={headerKeys}
        dataRows={contactList?.data || []}
        keyName={"client_contact_details_id"}
        exportFileName="Contact Details"
        loading={contactList?.loading}
        searchKeys={["first_name", "last_name", "email", "phone_number"]}
      />

      {/* Add Contact Form Dialog Component */}
      <AddContactFormDialog
        dialog={addFormDialog}
        handleCloseFormDialog={handleCloseFormDialog}
      />
    </>
  );
};

export default ContactList;
