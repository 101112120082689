import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const PrivateRoute = ({ children, title }) => {
  const { isLoggedIn } = useAuth();
  useEffect(() => {
    if (title) {
      document.title = `${(title ? `${title} - ` : "") || ""} Aacon`;
    } else {
      document.title = "Aacon";
    }
  }, [title]);

  let location = useLocation();

  return isLoggedIn ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default PrivateRoute;
