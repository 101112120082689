import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlogService from "../../services/BlogService";

const Blogs = () => {
  const dispatch = useDispatch();
  const { data: blogs } = useSelector((state) => state.blog);

  useEffect(() => {
    dispatch(BlogService());
  }, [dispatch]);

  const handleBlogClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      {blogs?.map((blog) => (
        <div key={blog.id} className="mainfdiv" onClick={() => handleBlogClick(blog.link)}>
          <div className="slider_item">
            <div className="slide_img">
              <img src={blog.post_img} alt={blog.title} />
            </div>
            <p>{blog.title}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Blogs;
