import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_NOTIFICATION_API_KEY,
  authDomain: process.env.REACT_APP_NOTIFICATION_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_NOTIFICATION_PROJECT_ID,
  storageBucket: process.env.REACT_APP_NOTIFICATION_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_NOTIFICATION_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_NOTIFICATION_APP_ID,
  measurementId: process.env.REACT_APP_NOTIFICATION_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);

export const messaging = getMessaging(firebaseApp);

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_NOTIFICATION_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
