import React from "react";

const TermsCondition = () => {
  return (
    <div>
      <a href={"https://aacon.io/terms-conditions/"}> Terms & Conditions</a>
    </div>
  );
};

export default TermsCondition;
