import { CancelBtn } from "../CustomButtons/CancelBtn";
import { PrimaryLoadingBtn } from "../CustomButtons/PrimaryBtn";

import styles from "./dialogBtn.module.css";

const DialogButtons = ({
  handleCancelBtn,
  handleClickBtn,
  loading,
  btnName,
  disabled,
}) => {
  return (
    <div className="mt-4">
      <CancelBtn
        type="button"
        className={styles.dialogBtnCancel}
        size="small"
        onClick={handleCancelBtn}
      >
        Cancel
      </CancelBtn>
      <PrimaryLoadingBtn
        type="submit"
        size="small"
        loading={loading}
        onClick={handleClickBtn}
        disabled={disabled}
        className={styles.dialogBtnAccept}
      >
        {btnName}
      </PrimaryLoadingBtn>
    </div>
  );
};

export default DialogButtons;
