import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Badge,
  Popover,
} from "@mui/material";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationsPopup from "../NotificationsPopup";
import styles from "./Header.module.css";
import { resetNewNotifications } from "../../store/actions/notificationActions";

const ProfileMenu = ({ handleLogoutBtn }) => {
  const dispatch = useDispatch();

  const [profileMenuEl, setProfileMenuEl] = useState(null);
  const [notificationPopoverAnchorEl, setNotificationPopoverAnchorEl] =
    useState(null);

  const user = useSelector((state) => state.user);
  const newNotificationCount = useSelector(
    (state) => state?.notification?.newNotificationCount
  );

  const openProfileMenu = Boolean(profileMenuEl);
  const handleAvatarClick = (event) => {
    setProfileMenuEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setProfileMenuEl(null);
  };

  const handleNotificationBtn = useCallback(
    (event) => {
      dispatch(resetNewNotifications());
      setNotificationPopoverAnchorEl(event.currentTarget);
    },
    [dispatch]
  );

  const handleCloseNotificationPopover = useCallback(() => {
    setNotificationPopoverAnchorEl(null);
  }, []);

  return (
    <div className="profile_div">
      <button className="btn_noti" onClick={handleNotificationBtn}>
        <Badge
          data-toggle="tooltip"
          data-placement="top"
          data-delay="0"
          title="Notifications"
          badgeContent={newNotificationCount}
          color="secondary"
        >
          <i className="fa fa-bell"></i>
        </Badge>
      </button>
      <Popover
        id={"notification-menu"}
        open={Boolean(notificationPopoverAnchorEl)}
        anchorEl={notificationPopoverAnchorEl}
        onClose={handleCloseNotificationPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}               
      >
        <NotificationsPopup handleClose={handleCloseNotificationPopover} />
      </Popover>
      <Button
        onClick={handleAvatarClick}
        size="small"
        className="headerUserBtn"
      >
        <i
          className={
            "fa fa-user " +
            styles.headerLogoIcon +
            " Header_headerLogoIcon__1LSxm"
          }
        />
        <span>
          {" "}
          {user?.firstname} {user?.lastname}
          <i
            className="fa fa-chevron-down"
            style={{ fontSize: "12px", marginLeft: "5px" }}
          />
        </span>
      </Button>

      <Menu
        anchorEl={profileMenuEl}
        open={openProfileMenu}
        onClose={handleCloseProfileMenu}
        onClick={handleCloseProfileMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="profile_box d-flex flex-column justify-content-center align-items-center px-4 py-3" style={{backgroundColor: "#f6f5ef"}}>
          <i className="fa fa-user-circle fa-3x mb-2" />
          <p className="mb-0 text-muted fs-sm fw-medium">
            {user?.firstname} {user?.lastname}
          </p>
          <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
            {user?.client_type}
          </p>
        </div>
        <Divider />

        {/* Logout Btn */}
        <MenuItem onClick={handleLogoutBtn} style={{backgroundColor: "#f6f5ef"}}>
          <ListItemIcon>
            <i className="fa fa-sign-out-alt" style={{ color: "#398edc" }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
