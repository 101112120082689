import { EventActionTypes } from "../actionTypes/EventActionTypes";

export const getEventsRequest = () => {
  return {
    type: EventActionTypes.GET_EVENTS_REQUEST,
  };
};

export const getEventsSuccess = (payload) => {
  return {
    type: EventActionTypes.GET_EVENTS_SUCCESS,
    payload,
  };
};

export const getEventsFailure = (payload) => {
  return {
    type: EventActionTypes.GET_EVENTS_FAILURE,
    payload,
  };
};

// Get Event Status
export const getEventStatusRequest = () => {
  return {
    type: EventActionTypes.GET_EVENT_STATUS_REQUEST,
  };
};

export const getEventStatusSuccess = (payload) => {
  return {
    type: EventActionTypes.GET_EVENT_STATUS_SUCCESS,
    payload,
  };
};

export const getEventStatusFailure = (payload) => {
  return {
    type: EventActionTypes.GET_EVENT_STATUS_FAILURE,
    payload,
  };
};

// Get Ongoing Event
export const getOngoingEventRequest = () => {
  return {
    type: EventActionTypes.GET_ONGOING_EVENT_REQUEST,
  };
};

export const getOngoingEventSuccess = (payload) => {
  return {
    type: EventActionTypes.GET_ONGOING_EVENT_SUCCESS,
    payload,
  };
};

export const getOngoingEventFailure = (payload) => {
  return {
    type: EventActionTypes.GET_ONGOING_EVENT_FAILURE,
    payload,
  };
};


// Show Event

export const getShowEventsRequest = () => {
  return {
    type: EventActionTypes.GET_SHOW_EVENT_REQUEST,
  };
};

export const getShowEventsSuccess = (payload) => {
  return {
    type: EventActionTypes.GET_SHOW_EVENT_SUCCESS,
    payload,
  };
};

export const getShowEventsFailure = (payload) => {
  return {
    type: EventActionTypes.GET_SHOW_EVENT_FAILURE,
    payload,
  };
};

