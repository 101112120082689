import React from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ThemeProvider } from "@mui/material";
import { themeOptions } from "./utils/theme";
import { createRoot } from "react-dom/client";

import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/css/index.css";
import "./assets/css/custom.css";

createRoot(document.getElementById("dms_user")).render(
  // <React.StrictMode>
    <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={themeOptions}>
            <App />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
