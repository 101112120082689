import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";

const CustomDialog = ({ dialogOpen, dialogTitle, handleClose, children }) => {
  return (
    <Dialog
      sx={{ maxWidth: "500px", width: "100%", margin: "auto" }}
      open={dialogOpen}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "#000",
          fontWeight: "600",
          backgroundImage: `linear-gradient(
            208deg,
            rgb(255 128 69 / 40%),
            rgb(57 142 220 / 40%)
          )`,
          backgroundColor: "white",
        }}
      >
        {dialogTitle}
      </DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
