import { Navigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import LoginForm from "./LoginForm";
import styles from "./Login.module.css";

const Login = () => {
  const { handleLogin, loading, isLoggedIn } = useAuth();

  if (isLoggedIn) return <Navigate to="/" />;

  return (
    <div className={styles.loginPageWrapper + " Login_loginPageWrapper__3RrFh"}>
      <div className={styles.formWrapper + " Login_formWrapper__3_DoM"}>
        <LoginForm handleLogin={handleLogin} loading={loading} />
      </div>
    </div>
  );
};

export default Login;
