export const sidebarLinks = [
  {
    path: "/",
    label: "Dashboard",
    iconClass: "fa fa-chart-pie",
  },
  {
    path: "/tempDocuments",
    label: "Temporary Documents",
    iconClass: "fa fa-file",
  },
  {
    path: "/directoryAccess",
    label: "Directory Access",
    iconClass: "fa fa-folder",
  },
  {
    path: "/contacts",
    label: "Contacts",
    iconClass: "fa fa-address-book",
  },
];
