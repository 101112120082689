import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DirectoryService } from "../../services/DirectoryService";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useFileUtilities } from "../../hooks/useFileUtilities";

const DirectoryAccess = () => {
  const dispatch = useDispatch();
  const { data: directory, loading } = useSelector((state) => state.directory);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const { getFileIconClass, handleDownloadFileBtn } = useFileUtilities();

  useEffect(() => {
    dispatch(DirectoryService());
  }, [dispatch]);

  const handleFolderClick = (folder) => {
    setSelectedFolder(selectedFolder === folder ? null : folder);
  };

  const cleanFileName = (fileName) => {
    return fileName
      .substring(fileName.lastIndexOf("/") + 1)
      .replace(/^\d+_/, "");
  };

  return (
    <div>
      {loading ? (
        <CircularProgress disableShrink size="45vh" color="secondary" />
      ) : (
        <Grid container spacing={2} style={{ height: "100vh" }}>
          <Grid item xs={6} style={{ height: "100%" }}>
            <Paper style={{ height: "100%", overflow: "auto" }}>
              <List className="css-h4y409-MuiList-root">
                {Array.isArray(directory) && directory.length > 0 ? (
                  directory.map((folder, index) => (
                    <ListItem
                      button
                      key={index}
                      onClick={() => handleFolderClick(folder)}
                      className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.MuiListItem-button.css-18ii6ut-MuiButtonBase-root-MuiListItem-root css-17ejul6 css-18ii6ut-MuiButtonBase-root-MuiListItem-root"                  
                    >
                      <ListItemIcon>
                        {selectedFolder === folder ? (
                          <i
                            className="fa fa-caret-down"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                        )}
                      </ListItemIcon>
                      <ListItemIcon>
                        <i
                          className="fa fa-folder text-primary"
                          aria-hidden="true"
                        ></i>
                      </ListItemIcon>
                      <ListItemText primary={folder.folder_name} />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body2" align="center">
                    No folders available
                  </Typography>
                )}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={6} style={{ height: "100%" }}>
            <Paper style={{ height: "100%", overflow: "auto" }}>
              {selectedFolder && (
                <Typography variant="h6" align="center" className="subheading">
                  {selectedFolder.folder_name}
                </Typography>
              )}
              <List className="css-h4y409-MuiList-root">
                {selectedFolder ? (
                  selectedFolder?.files.length > 0 ? (
                    selectedFolder?.files.map((file, fileIndex) => (
                      <ListItem
                        key={fileIndex}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "rgba(207,227,231,.514)",
                          margin: "8px 0",
                          borderRadius: "8px",
                          padding: "10px",
                        }}
                      >
                        <ListItemIcon>
                          <i
                            className={getFileIconClass(file)}
                            style={{ fontSize: "25px" }}
                          ></i>
                        </ListItemIcon>
                        <ListItemText primary={cleanFileName(file)} />
                        <IconButton
                          onClick={() =>
                            handleDownloadFileBtn(file, cleanFileName(file))
                          }
                        >
                          <i className="fa fa-download" aria-hidden="true"></i>
                        </IconButton>
                      </ListItem>
                    ))
                  ) : (
                    <Typography variant="body2" align="center">
                      No files to show
                    </Typography>
                  )
                ) : (
                  <Typography variant="body2" align="center">
                    Select a folder to view files
                  </Typography>
                )}
              </List>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default DirectoryAccess;
