import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlogService from "../../services/BlogService";
import Slider from "react-slick";
import RandomSkeleton from "../../components/LoadingSpinner/RandomSkeleton";

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 3,
  initialSlide: 0,
  swipeToSlide: false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
      },
    },
    {
      breakpoint: 1190,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function BlogList() {
  const dispatch = useDispatch();
  const { data: blogs, loading } = useSelector((state) => state.blog);

  useEffect(() => {
    dispatch(BlogService());
  }, [dispatch]);

  const handleBlogClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <h3>Latest Blogs</h3>

      <div className="slider-container">
        {loading && (
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <RandomSkeleton color="#d3d3d363" width="400px" height="320px" />
            <RandomSkeleton color="#d3d3d363" width="400px" height="320px" />
            <RandomSkeleton color="#d3d3d363" width="400px" height="320px" />
          </div>
        )}
        {!!blogs?.length && !loading && (
          <Slider {...sliderSettings}>
            {blogs?.map((blog) => (
              <div
                key={blog.id}
                className="mainfdiv"
                onClick={() => handleBlogClick(blog.link)}
              >
                <div className="slider_item">
                  <div className="slide_img">
                    <img src={blog.post_img} alt={blog.title} />
                  </div>
                  <p>
                    {blog.title.length > 90
                      ? `${blog.title.substring(0, 90)}...`
                      : blog.title}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        )}
        <a href={"https://www.aacon.io/blog/"} className="com_btn">
          View All
        </a>
      </div>
    </>
  );
}
