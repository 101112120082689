import { toast } from "react-toastify";
import axiosConfig from "../utils/axiosConfig";
import {
  directoryListFailure,
  directoryListRequest,
  directoryListSuccess,
} from "../store/actions/directoryActions";

export const DirectoryService = (data) => {
  return (dispatch) => {
    dispatch(directoryListRequest());

    axiosConfig
      .post("client/directoriesList", data)
      .then((res) => {
        if (res?.data?.status === 1) {
          dispatch(directoryListSuccess(res.data?.responsedata));
        } else {
          toast.error(res?.data?.message);
          dispatch(directoryListFailure(res.data?.message));
        }
      })
      .catch((err) => {
        toast.error("Error Fetching in Directory List....");
        dispatch(directoryListFailure(err));
      });
  };
};
