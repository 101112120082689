import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShowEvents } from "../../services/EventService";
import { useParams } from "react-router-dom";
import RandomSkeleton from "../../components/LoadingSpinner/RandomSkeleton";
import { useFileUtilities } from "../../hooks/useFileUtilities";

const ShowEventDialog = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { getFileIconClass, handleDownloadFileBtn } = useFileUtilities();

  const showEventDetails = useSelector((state) => state.event.showEventDetails);
  const loading = useSelector((state) => state.event.loading);

  useEffect(() => {
    if (params.event_id) {
      dispatch(ShowEvents({ event_id: params.event_id }));
    }
  }, [dispatch, params.event_id]);

  return (
    <>
      <div className="task_detail">
        {loading ? (
          <RandomSkeleton animation="wave" width={380} height={140} />
        ) : (
          <div className="workcode">
            <h6>Work Code</h6>
            <span>{showEventDetails?.event?.job_code_name}</span>
          </div>
        )}
        {loading ? (
          <RandomSkeleton animation="wave" width={380} height={140} />
        ) : (
          <div className="username">
            <h6>User Documents</h6>
            {showEventDetails?.documents?.length > 0 ? (
              showEventDetails.documents.map((doc, index) => (
                <div key={index}>
                  <p>
                    <i className={getFileIconClass(doc.file_path)}></i>
                    {doc.file_name}
                  </p>
                  <i
                    className="fa fa-download"
                    aria-hidden="true"
                    onClick={() =>
                      handleDownloadFileBtn(
                        `https://api.adaptonus.com:8080/storage/${doc.file_path}`,
                        doc.file_name
                      )
                    }
                  ></i>
                </div>
              ))
            ) : (
              <p>No user documents found</p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ShowEventDialog;
