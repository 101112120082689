const headerKeys = (handleShowEventDetails) => [
  {
    name: "Job Code Name",
    key: "job_code_name",
    formatter: (data) => {
      return (
        <span>{data?.job_code_name}</span>
      );
    },
  },
  {
    name: "Event Time",
    key: "event_time",
    formatter: (data) => {
      return <span>{(data?.event_time || "").split(" ")[0]}</span>;
    },
  },
  {
    name: "Logged Hours",
    key: "event_duedate",
    formatter: (data) => {
      return <span>{(data?.event_duedate || "").split(" ")[0]}</span>;    
    },
  },
  {
    name: "Action",
    key: "",
    formatter: (data) => {
      return (
        <i
          className="fa fa-eye eye_icon"
          onClick={() => handleShowEventDetails(data, data.event_id)}
        />
      );
    },
  },
];

export default headerKeys;
