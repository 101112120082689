import { authActionTypes } from "../actionTypes/authActionTypes";

const initialState = {
  fcm_token: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.LOGIN_SUCCESS:
      return action.payload;

    case authActionTypes.LOGOUT_SUCCESS:
      return {};

    case authActionTypes.SET_NOTIFICATION_TOKEN:
      return {
        ...state,
        fcm_token: action.payload,
      };
    default:
      return state;
  }
};
