import { EventActionTypes } from "../actionTypes/EventActionTypes";

const initialState = {
  loading: false,
  data: "",
  error: "",
  ongoingEvents: [],
  showEventDetails: {},
  getEventStatus: "",
  statusError: "",
  statusLoading: false,
};

export const EventReducer = (state = initialState, action) => {
  switch (action.type) {
    case EventActionTypes.GET_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case EventActionTypes.GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };

    case EventActionTypes.GET_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        error: action.payload,
      };

    // Get event status
    case EventActionTypes.GET_EVENT_STATUS_REQUEST:
      return {
        ...state,
        // getEventStatus: "",
        statusError: "",
        statusLoading: true,
      };

    case EventActionTypes.GET_EVENT_STATUS_SUCCESS:
      return {
        ...state,
        getEventStatus: action.payload,
        statusError: "",
        statusLoading: false,
      };

    case EventActionTypes.GET_EVENT_STATUS_FAILURE:
      return {
        ...state,
        getEventStatus: "",
        statusError: action.payload,
        statusLoading: false,
      };

      // Get ongoing event
    case EventActionTypes.GET_ONGOING_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case EventActionTypes.GET_ONGOING_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        ongoingEvents: action.payload,
        error: "",
      };

    case EventActionTypes.GET_ONGOING_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        error: action.payload,
      };

       // Get show event
    case EventActionTypes.GET_SHOW_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case EventActionTypes.GET_SHOW_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        showEventDetails: action.payload,
        error: "",
      };

    case EventActionTypes.GET_SHOW_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        error: action.payload,
      };
    default:
      return state;
  }
};
