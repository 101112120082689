import { contactActionTypes } from "../actionTypes/contactActionTypes";

// List
export const contactListRequest = () => {
  return {
    type: contactActionTypes.CONTACT_LIST_REQUEST,
  };
};

export const contactListSuccess = (payload) => {
  return {
    type: contactActionTypes.CONTACT_LIST_SUCCESS,
    payload,
  };
};

export const contactListFailure = (payload) => {
  return {
    type: contactActionTypes.CONTACT_LIST_FAILURE,
    payload,
  };
};

// Add
export const addContactRequest = () => {
  return {
    type: contactActionTypes.ADD_CONTACT_REQUEST,
  };
};

export const addContactSuccess = (payload) => {
  return {
    type: contactActionTypes.ADD_CONTACT_SUCCESS,
    payload,
  };
};

export const addContactFailure = (payload) => {
  return {
    type: contactActionTypes.ADD_CONTACT_FAILURE,
    payload,
  };
};
