import React, { useMemo } from "react";
import { SwipeableDrawer, Backdrop } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  hideNavigationMenu,
  showNavigationMenu,
} from "../../store/actions/uiActions";
import { sidebarLinks } from "./sidebarLinks";
import logo from "../../assets/images/logo.png";
import styles from "./NavigationSidebar.module.css";

const NavigationSidebar = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const menuState = useSelector((state) => state?.ui?.sidebarMenu);
  const location = useLocation();

  const closeDrawer = () => {
    dispatch(hideNavigationMenu());
  };

  const openDrawer = () => {
    dispatch(showNavigationMenu());
  };

  const handleBackdropClick = () => {
    closeDrawer();
  };

  const filteredLinks = useMemo(() => {
    if (user?.client_type === "Personal") {
      return sidebarLinks?.filter((link) => link.path !== "/contacts");
    }
    return sidebarLinks;
  }, [user?.client_type]);

  return (
    <>
      <SwipeableDrawer
        anchor={"left"}
        open={menuState}
        onClose={closeDrawer}
        onOpen={openDrawer}
        className={styles.drawerWrapper}
      >
        <div className="mobile-logo">          
            <img src={logo} className={styles.logo} alt="Logo" />          
        </div>
        <ul
          className={
            styles.menuLinksWrapper +
            " NavigationSidebar_menuLinksWrapper__1bsNA"
          }
        >
          {(filteredLinks || []).map((l) => {
            const isCurrent = location?.pathname === l.path;
            return (
              <li key={l.path}>
                <Link
                  className={
                    styles.menuLink +
                    " " +
                    (isCurrent ? styles.menuLinkSelected : "")
                  }
                  to={l.path}
                >
                  {l.iconClass && (
                    <i
                      className={
                        l.iconClass +
                        " " +
                        (isCurrent ? styles.menuIconSelected : styles.menuIcon)
                      }
                      style={{
                        marginRight: "0.5rem",
                      }}
                    />
                  )}
                  {l.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </SwipeableDrawer>
      {menuState && (
        <Backdrop
          open={menuState}
          onClick={handleBackdropClick}
          className={styles.backdrop}
        />
      )}
    </>
  );
};

export default NavigationSidebar;
