import OtpInput from "react-otp-input";

function CheckOTP({
  setOtpInput,
  otpInput,
  emailInput,
  handleCheckOtp,
  data,
  resetForgotPassword,
  otpTimer,
  handleSendOtp,
}) {
  return (
    <>
      <h1 className="text-black">Reset Password</h1>
      <p className="fw-medium text-muted">
        An OTP has been sent to <b>{emailInput}</b>
        <span
          className="btn btn-link text-sm ml-0"
          style={{ fontSize: "0.8rem" }}
          onClick={() => resetForgotPassword()}
        >
          Change
        </span>
      </p>
      <OtpInput
        inputStyle="otpInputStyle"
        numInputs={6}
        isDisabled={data.checkOtpLoading}
        hasErrored={data.checkOtpRequested && otpInput?.length < 6}
        errorStyle="otpInputerror"
        value={otpInput}
        onChange={(value) => setOtpInput(value)}
        separator={<span>-</span>}
        isInputNum={true}
        shouldAutoFocus
      />
      {data.otpSendRequested && data.checkOtpError && (
        <span className="help-block invalid-feedback fadeIn">
          {data.checkOtpError}
        </span>
      )}

      <div className="row mt-4">
        <div className="col-md-12 col-xl-12 d-flex justify-content-between h-50">
          <span className="d-flex flex-column ">
            <button
              disabled={otpTimer > 0 ? true : false}
              className="btn btn-sm btn-primary "
              style={{ width: "max-content" }}
              type="button"
              onClick={handleSendOtp}
            >
              Resend OTP
            </button>
            {otpTimer > 0 && (
              <span className="text-muted text-sm">
                You can re-send OTP in <b>{otpTimer}</b>s
              </span>
            )}
          </span>
          <button
            type="button"
            onClick={handleCheckOtp}
            className="btn btn-alt-primary float-right h-25"
            disabled={data?.checkOtpLoading}
          >
            {!data?.checkOtpLoading ? (
              <>
                <i className="fa fa-fw fa-arrow-right opacity-50">&nbsp;</i>
                Next
              </>
            ) : (
              <>
                <i className="fas fa-spinner fa-spin" />
                &nbsp;Please Wait...
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default CheckOTP;
