import moment from "moment";

export const isObject = (obj) =>
  typeof obj === "object" && !Array.isArray(obj) ? true : false;

export const filterEventsByDateRange = (events, startDate, endDate) => {
  return events?.filter((event) => {
    const eventDate = moment(event.event_duedate, "YYYY/MM/DD");
    return eventDate.isBetween(startDate, endDate, null, "[]");
  });
};
