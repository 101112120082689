import { useState } from "react";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useForgotPassword from "../../hooks/useForgotPassword";
import ChangePassword from "./ChangePassword";
import CheckOTP from "./CheckOTP";
import SendOTP from "./SendOTP";
import styles from "../Authentication/Login.module.css";
import { toast } from "react-toastify";

function ForgotPassword() {
  const {
    data,
    getOtp,
    verifyOtp,
    changePassword,
    resetForgotPassword,
    otpTimer,
  } = useForgotPassword();
  const [usernameInput, setUsernameInput] = useState("");
  const [otpInput, setOtpInput] = useState("");
  const [passwordsInput, setPasswordsInput] = useState({
    password: "",
    passwordError: "",
    confirm_password: "",
    confirm_passwordError: "",
    submittedOnce: false,
  });

  const handleSendOtp = () => {
    getOtp(usernameInput);
  };

  const handleCheckOtp = () => {
    if (otpInput.length < 6) {
      toast.error("Please enter a valid OTP");
      return;
    }
    verifyOtp(usernameInput, otpInput);
  };

  const validatePasswordInput = (obj) => {
    let error = "";
    if (obj?.password !== obj?.confirm_password) {
      setPasswordsInput((prev) => ({
        ...prev,
        confirm_passwordError: "Password & Current Password Do not Match!",
      }));
      error = "1";
    } else {
      setPasswordsInput((prev) => ({
        ...prev,
        confirm_passwordError: "",
      }));
      error = "";
    }

    if (obj?.password.length < 6) {
      setPasswordsInput((prev) => ({
        ...prev,
        passwordError: "Password Length Must be Atleast of 6 Characters",
      }));
      error = "1";
    } else {
      setPasswordsInput((prev) => ({
        ...prev,
        passwordError: "",
      }));
      error = "";
    }
    return error;
  };

  const handleChangePassword = () => {
    setPasswordsInput((prev) => ({ ...prev, submittedOnce: true }));
    if (validatePasswordInput(passwordsInput)) return;
    const isClean =
      !passwordsInput.passwordError && !passwordsInput.confirm_passwordError;

    if (!isClean) return;
    changePassword(
      usernameInput,
      otpInput,
      passwordsInput.password,
      passwordsInput.confirm_password
    );
  };

  return (
    <div className={styles.loginPageWrapper + " Login_loginPageWrapper__3RrFh"}>
      <div className={styles.formWrapper + " Login_formWrapper__3_DoM"}>
        <div className={styles.formStyles + " Login_formStyles__2sp0R"}>
          <TransitionGroup>
            <CSSTransition key={data?.section} timeout={300} classNames="fade">
              <div>
                {data?.section === "otp" && (
                  <CheckOTP
                    resetForgotPassword={resetForgotPassword}
                    emailInput={usernameInput}
                    otpInput={otpInput}
                    setOtpInput={setOtpInput}
                    handleCheckOtp={handleCheckOtp}
                    data={data}
                    otpTimer={otpTimer}
                    handleSendOtp={handleSendOtp}
                  />
                )}

                {data?.section === "email" && (
                  <SendOTP
                    setEmailInput={setUsernameInput}
                    emailInput={usernameInput}
                    data={data}
                    handleSendOtp={handleSendOtp}
                  />
                )}
                {data?.section === "password" && (
                  <ChangePassword
                    data={data}
                    validatePasswordInput={validatePasswordInput}
                    passwordsInput={passwordsInput}
                    setPasswordsInput={setPasswordsInput}
                    handleChangePassword={handleChangePassword}
                  />
                )}
                {data?.section === "successfull" && (
                  <>
                    <p>Password Changed Successfully</p>
                    <Link to="/login">Login</Link>
                  </>
                )}
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
