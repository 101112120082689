import { notificationActionTypes } from "../actionTypes/notificationActionTypes";

const initialState = {
  loading: false,
  sending: false,
  data: "",
  lastUpdated: "",
  newNotificationCount: Number(localStorage.getItem("newNotiCount") || 0),  
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationActionTypes.GET_USER_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case notificationActionTypes.GET_USER_NOTIFICATIONS_SUCCESS: {
      const currentDateTime = new Date();
      return {
        ...state,
        loading: false,
        error: "",
        data: action.payload,
        lastUpdated: currentDateTime,
      };
    }

    case notificationActionTypes.GET_USER_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: "",
      };
    }   

    case notificationActionTypes.NOTIFICATION_RECEIVED: {
      const nmwNotificationCount = state.newNotificationCount + 1;
      localStorage.setItem("newNotiCount", nmwNotificationCount);
      return {
        ...state,
        newNotificationCount: nmwNotificationCount,
      };
    }

    case notificationActionTypes.RESET_NEW_NOTIFICATIONS: {
      localStorage.setItem("newNotiCount", 0);

      return {
        ...state,
        newNotificationCount: 0,
      };
    }

    default:
      return state;
  }
};
