import { useState } from "react";
import styles from "./Login.module.css";
import {
  FormValidation,
  FormValidationSchema,
} from "../../utils/FormValidations";
import { Link } from "react-router-dom";
import logo_header from "../../assets/images/logo.png";

const loginValidationSchema = {
  email: new FormValidationSchema().isRequired("Email is Required"),
  password: new FormValidationSchema()
    .isRequired("Password is Required")
    .min(6),
};

const LoginForm = ({ handleLogin, loading }) => {
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });

  const [loginValidation] = useState(
    () => new FormValidation(loginValidationSchema)
  );

  const [errors, setErrors] = useState({});

  loginValidation.observer(loginFormData, (err) => {
    setErrors(err);
  });

  const onSubmit = (e) => {
    e.preventDefault();
    loginValidation.submit(loginFormData);
    if (loginValidation.hasErrors()) {
      setErrors(loginValidation.errors);
      return;
    }
    handleLogin(loginFormData);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setLoginFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <form
      className={styles.formStyles + " Login_formStyles__2sp0R"}
      onSubmit={onSubmit}
    >
      <div className="logo">
        {/* <h1 className="font-w700 mb-2 text-black">Sign In</h1> */}
        <img src={logo_header} className="mobile_logo" alt="Logo" />
        {/* <h4>Welcome, please login.</h4> */}
      </div>
      <div className={styles.textFieldWrapper}>
        <input
          className={styles.textField}
          name="email"
          type="text"
          onChange={handleChange}
          value={loginFormData.email}
          placeholder="Username"
        />
        {/* {errors && (
          <p className="text-danger m-0 p-0 mt-1" style={{ fontSize: "1rem" }}>
            {errors?.email?.message || <>&nbsp;</>}
          </p>
        )} */}
      </div>
      <div className={styles.textFieldWrapper}>
        <input
          className={styles.textField}
          name="password"
          type="password"
          onChange={handleChange}
          value={loginFormData.password}
          placeholder=" Password"
        />

        {/* {errors && (
          <p className="text-danger m-0 p-0 mt-1" style={{ fontSize: "1rem" }}>
            {errors?.password?.message || <>&nbsp;</>}
          </p>
        )} */}
      </div>

      <div className="logindiv">
        <Link
          className="text-gray-500 text-sm font-medium block lg:inline-block mb-1"
          to="/forgotPassword"
        >
          Forgot Password?
        </Link>
        <button
          className={`${styles.loginBtn} ${
            loading || loginValidation.hasErrors()
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          type="submit"
          disabled={loading || loginValidation.hasErrors()}
        >
          {loading ? (
            <>
              <i className="fa fa-spin fa-spinner text-white" />
            </>
          ) : (
            "Login"
          )}
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
