import Chat from "../ChatModule/Chat";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import NavigationSidebar from "../NavigationSidebar/NavigationSidebar";

import styles from "./Layout.module.css";
import PageTitle from "./PageTitle";

const Layout = ({ children, title }) => {
  return (
    <>
      <Header />
      <NavigationSidebar />
      <div className={styles.layoutContent}>
        <div className="container">
        {title && <PageTitle title={title} />}
        <div>{children}</div>
      </div>
      </div>
      <Chat />
      <Footer />
    </>
  );
};

export default Layout;
