import styles from "./Layout.module.css";

const PageTitle = ({ title, children }) => {
  return (
    <div
      className={
        styles.layoutTitleWrapper + " Layout_layoutTitleWrapper__GVRWR"
      }
    >
      <h2 className="comntitle">{title}</h2>
      {children}
    </div>
  );
};

export default PageTitle;
