import { Switch } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import DialogButtons from "../../components/CustomDialog/DialogButtons";
import CustomLabel from "../../components/FormComponents/CustomLabel";
import FormComponent from "../../components/FormComponents/FormComponent";
import { AddContact } from "../../services/ContactService";
import {
  FormValidation,
  FormValidationSchema,
} from "../../utils/FormValidations";

const formValidationSchema = {
  first_name: new FormValidationSchema().isRequired("First Name is Required"),
  last_name: new FormValidationSchema().isRequired("Last Name is Required"),
  email: new FormValidationSchema().isRequired("Email is Required").email(),
  phone_number: new FormValidationSchema().isRequired(
    "Phone Number is Required"
  ),
  allow_login: "no",
};

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  allow_login: "no",
};
const AddContactFormDialog = ({ dialog, handleCloseFormDialog }) => {
  const [formData, setFormData] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [formValidation] = useState(
    () => new FormValidation(formValidationSchema)
  );

  const dispatch = useDispatch();
  const added = useSelector((state) => state?.contact?.added);

  formValidation.observer(formData, (err) => {
    setErrors(err);
  });

  const resetForm = useCallback(() => {
    formValidation.resetValidation();
    setErrors({});
    setFormData(initialValues);
  }, [formValidation]);

  useEffect(() => resetForm, [resetForm]);

  const handleCancelBtn = () => {
    resetForm();
    handleCloseFormDialog();
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSwitchChange = (e) => {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      allow_login: prev.allow_login === "yes" ? "no" : "yes",
    }));
  };

  const handleAddBtn = async (e) => {
    e.preventDefault();
    formValidation.submit(formData);
    if (formValidation.hasErrors()) {
      setErrors(formValidation.errors);
      return;
    }
    await dispatch(AddContact(formData));
    handleCancelBtn();
  };

  return (
    <CustomDialog
      dialogOpen={dialog.open === true}
      handleClose={handleCancelBtn}
      dialogTitle="Add New Contact"
    >
      {/* Form Data */}
      <form className="row" onSubmit={handleAddBtn} noValidate>
        <div className={formItemClass}>
          <CustomLabel label="First Name" required />
          <FormComponent
            submitted={formValidation.isSubmitted}
            error={errors?.first_name?.message}
            type="text"
            value={formData?.first_name || ""}
            placeholder="First Name"
            onChange={handleOnChange}
            name="first_name"
          />
        </div>

        <div className={formItemClass}>
          <CustomLabel label="Last Name" required />
          <FormComponent
            submitted={formValidation.isSubmitted}
            error={errors?.last_name?.message}
            type="text"
            value={formData?.last_name || ""}
            placeholder="Last Name"
            onChange={handleOnChange}
            name="last_name"
          />
        </div>

        <div className={formItemClass}>
          <CustomLabel label="Email ID" required />
          <FormComponent
            submitted={formValidation.isSubmitted}
            error={errors?.email?.message}
            type="email"
            value={formData?.email || ""}
            placeholder="Email ID"
            onChange={handleOnChange}
            name="email"
          />
        </div>
        <div className={formItemClass}>
          <CustomLabel label="Mobile Number" required />
          <FormComponent
            submitted={formValidation.isSubmitted}
            error={errors?.phone_number?.message}
            type="text"
            value={formData?.phone_number || ""}
            placeholder="Mobile Number"
            onChange={handleOnChange}
            name="phone_number"
          />
        </div>
        <div className="col-xl-6 col-md-6 mb-2 d-flex flex-column justify-content-center w-100 align-items-center">
          {/* Allow Login */}
          <CustomLabel label="Allow Login" />
          <Switch
            name="allow_login"
            sx={{ float: "right" }}
            checked={formData?.allow_login === "yes" ? true : false}
            onClick={handleSwitchChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>

        {/* Buttons */}

        <div className="mt-1">
          <DialogButtons
            handleCancelBtn={handleCancelBtn}
            // handleClickBtn={}
            loading={added}
            btnName="Add"
            disabled={added}
          />
        </div>
      </form>
    </CustomDialog>
  );
};

const formItemClass = "col-xl-6 col-md-6 mb-2";

export default AddContactFormDialog;
