import { contactActionTypes } from "../actionTypes/contactActionTypes";

const initialState = {
  data: "",
  loading: false,
  error: "",
  added: false,
};

export const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    // List
    case contactActionTypes.CONTACT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case contactActionTypes.CONTACT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };

    case contactActionTypes.CONTACT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        error: action.payload,
      };

    // Add
    case contactActionTypes.ADD_CONTACT_REQUEST:
      return {
        ...state,
        added: true,
        error: "",
      };

    case contactActionTypes.ADD_CONTACT_SUCCESS:
      return {
        ...state,
        added: false,
        data: action.payload,
        error: "",
      };

    case contactActionTypes.ADD_CONTACT_FAILURE:
      return {
        ...state,
        added: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
