import RoutesComponent from "./routes/Routes";
import { Bounce, ToastContainer } from "react-toastify";
import useNotification from "./hooks/useNotification";
import "react-toastify/dist/ReactToastify.css";
// import "./assets/css/App.css";

function App() {  
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
        
      }).catch((err) => {
        console.log('Service Worker registration failed:', err);
      });
  }

  useNotification();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        transition={Bounce}
      />
      <RoutesComponent />
    </>
  );
}

export default App;
